import { combineReducers } from 'redux';

import { CATEGORIES_REQUEST, CATEGORIES_RECEIVE, CATEGORIES_ERROR } from '../actions/categories';

export default (state = {}, action) => {

    const isFetching = (state = false, action) => {
        switch (action.type) {
            case CATEGORIES_REQUEST:
                return true;
            case CATEGORIES_RECEIVE:
            case CATEGORIES_ERROR:
                return false;
            default:
                return state;
        }
    };

    const valid = (state = false, action) => {
        switch (action.type) {
            case CATEGORIES_RECEIVE:
                return true;
            default:
                return state;
        }
    };

    const items = (state = [], action) => {
        switch (action.type) {
            case CATEGORIES_RECEIVE:
                return action.payload;
            default:
                return state;
        }
    };

    return combineReducers({
        isFetching,
        valid,
        items
    })(state, action);
}