import * as React from "react";
import { connect } from "react-redux";
import moment from 'moment';
import styled from 'styled-components';

import { Scrollable, Pill, Flex, Loader } from '../../components';
import {stormyGray} from "../../variables";
import { exports } from '../../../actions';
import { STATIC_URL} from "../../../config";

const ItemDate = styled.span`
    color: ${stormyGray};
    font-weight: 600;
`;

class Export extends React.PureComponent {

    render() {
        const { item } = this.props;

        return <Flex className="justify-content-between py-1">
            <Flex>{item.name} <ItemDate>({moment(item.generated).format('DD. MM. YYYY HH:mm')})</ItemDate></Flex>
            <Flex style={{width: '100px'}}>
                {3 === item.status && <Pill backgroundColor="black" className="cursor-pointer px-3 py-1">
                    <a href={`${STATIC_URL}${item.file}`} className="text-white" target="_blank" rel="noopener noreferrer">Stiahnuť</a>
                </Pill>}
                {0 === item.status && <Loader />}
            </Flex>
        </Flex>
    }
}

class Exports extends React.PureComponent {

    componentDidMount() {
        const { getExports } = this.props;

        getExports();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.type !== prevProps.type) {
            const { getExports } = this.props;

            getExports();
        }
        if (this.props.exports.isFetching !== prevProps.exports.isFetching) {
            const { getExports } = this.props;

            const found = this.props.exports.items.filter(item => 0 === item.status);

            found.length && !this.props.exports.isFetching && getExports();
        }
    }

    render(): React.Node {
        const { exports: { items }} = this.props;

        return <Scrollable direction="column" flex className="p-2">
            <div>
                <h1 className="mb-4">ARCHÍV</h1>

                {items.map(item => <Export key={item.id} item={item} />)}
            </div>
        </Scrollable>
    }
}

export default connect(
    (state, props) => {
        return {
            exports: state.exports,
            client: state.client
        }
    },
    (dispatch, props) => {
        return {
            getExports: (client) => dispatch(exports(client.data.id, props.type))
        }
    },
    (stateProps, dispatchProps, ownProps) => {
        return Object.assign({}, ownProps, stateProps, dispatchProps, {
            getExports: () => dispatchProps.getExports(stateProps.client, stateProps.exports)
        });
    }
)(Exports);
