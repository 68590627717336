import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router';

import {auth} from "../../queries";

class Clients extends React.PureComponent {

    componentWillMount() {
        const { auth } = this.props;

        auth();
    }

    render(): React.Node {
        return null
    }
}

Clients = connect(
    (state, props) => ({}),
    (dispatch, props) => {
        return {
            auth: () => dispatch(auth({
                token: props.match.params.token
            }))
                .then(response => {
                    if (response.payload.data) {
                        props.history.push('/monitoring');
                    }
                })
        }
    }
)(Clients);

export default withRouter(Clients);