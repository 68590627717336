import * as React from "react";
import { connect } from "react-redux";
import 'react-vis/dist/style.css';

import { DoubleColumnLayout } from '..';
import { Loader } from '../../components';
import Sidebar from './Sidebar';
import Scrollable from "../../components/Scrollable";
import Filter from './Filter';

class Histogram extends React.PureComponent {

    componentDidMount() {
        const { onSubmit } = this.props;

        onSubmit();
    }

    handleClick = () => {
        const { onSubmit } = this.props;

        onSubmit();
    };

    render(): React.Node {
        const {name, query, initialValues, isFetching, children} = this.props;

        return <DoubleColumnLayout sidebar={<Sidebar/>} toolbar={<Filter form={name} query={query} initialValues={initialValues}/>}>
            <Scrollable flex direction="column" className="m-4">
                {isFetching ? <Loader/> : children}
            </Scrollable>
        </DoubleColumnLayout>
    }
}

export default connect(
    (state, props) => {
        return {
            isFetching: state.graphql[props.name] ? state.graphql[props.name].isFetching : null
        }
    }
)(Histogram);
