import gql from 'graphql-tag';

import {graphql} from "../../../../actions";

export default (name, variables) => graphql(name, 'analysis', gql`query Analysis(
    $client: Int!
    $from: String!
    $to: String!
) {
    analysis(
        client: $client, 
        from: $from, 
        to: $to, 
        field: "COUNT(a.id)", 
        buckets: [
            {
                bucket: "(SELECT name AS id FROM categories WHERE client_id = :client_id)", 
                field: "c.name"
            },
            {
                bucket: "(SELECT DISTINCT monitoring_date AS id FROM articles WHERE monitoring_date >= \\"${variables.from}\\" AND monitoring_date <= \\"${variables.to}\\")", 
                field: "a.monitoring_date"
            }
        ]
    ) {
        keys
        count
    }
}`, variables)