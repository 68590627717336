import { combineReducers } from 'redux';

import { CLIENTS_REQUEST, CLIENTS_RECEIVE, CLIENTS_ERROR } from '../actions/clients';

export default (state = {}, action) => {

    const isFetching = (state = false, action) => {
        switch (action.type) {
            case CLIENTS_REQUEST:
                return true;
            case CLIENTS_RECEIVE:
            case CLIENTS_ERROR:
                return false;
            default:
                return state;
        }
    };

    const valid = (state = false, action) => {
        switch (action.type) {
            case CLIENTS_RECEIVE:
                return true;
            default:
                return state;
        }
    };

    const items = (state = [], action) => {
        switch (action.type) {
            case CLIENTS_RECEIVE:
                return action.payload;
            default:
                return state;
        }
    };

    return combineReducers({
        isFetching,
        valid,
        items
    })(state, action);
}