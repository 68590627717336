import { combineReducers } from 'redux';

import { ARTICLES_REQUEST, ARTICLES_RECEIVE, ARTICLES_ERROR } from '../actions/monitoring';

export default (state = {}, action) => {

    const isFetching = (state = false, action) => {
        switch (action.type) {
            case ARTICLES_REQUEST:
                return true;
            case ARTICLES_RECEIVE:
            case ARTICLES_ERROR:
                return false;
            default:
                return state;
        }
    };

    const valid = (state = false, action) => {
        switch (action.type) {
            case ARTICLES_RECEIVE:
                return true;
            default:
                return state;
        }
    };

    const items = (state = [], action) => {
        switch (action.type) {
            case ARTICLES_RECEIVE:
                return action.payload.data;
            default:
                return state;
        }
    };

    const meta = (state = { count: 0, offset: 0 }, action) => {
        switch (action.type) {
            case ARTICLES_RECEIVE:
                return action.payload.meta;
            default:
                return state;
        }
    };

    return combineReducers({
        isFetching,
        valid,
        items,
        meta
    })(state, action);
}