//@flow

import styled from 'styled-components';

import {Rating} from '.';
import {seagull, shark} from "../variables";

export default styled(Rating)`
    background-color: ${seagull}
    
    &:before {
        content: "";
        width: 11px;
        height: 0;
        border-bottom: 1px solid ${shark};
        position: absolute;
        top: 10px;
        left: 5px;
        border-radius: 1px;
    }
`;
