//@flow

import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { Pagination } from '../../../components';
import {filter} from "../../../../queries";

export default connect(
    (state, props) => {
        const values = formValueSelector('filter')(state, 'query', 'date', 'categories', 'sources', 'ratings', 'ave', 'periodicity', 'type', 'scope');
        return {
            values: values,
            client: state.client,
            page: (state.graphql.filter?.data?.meta?.offset / state.graphql.filter?.data?.meta?.limit) + 1
        }
    },
    (dispatch, props) => {
        return {
            onChange: (client, values, page) => dispatch(filter({
                client: client.data.id,
                query: values.query ? values.query : null,
                from: values.date.from,
                to: values.date.to,
                dateType: values.date.type,
                categories: values.categories,
                sources: values.sources,
                ratings: values.ratings || null,
                periodicity: undefined === values.periodicity ? null : values.periodicity,
                type: undefined === values.type ? null : values.type,
                scope: undefined === values.scope ? null : values.scope,
                aveFrom: values.ave?.from || null,
                aveTo: values.ave?.to || null,
                offset: (page - 1) * 30
            }))
        }
    },
    (stateProps, dispatchProps, ownProps) => {
        return Object.assign({}, ownProps, stateProps, {
            onChange: page => dispatchProps.onChange(stateProps.client, stateProps.values, page),
        });
    }
)(Pagination);
