//@flow

import * as React from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import { Field, change } from 'redux-form';
import { Experiment, Variant, experimentDebugger, mixpanelHelper } from '@marvelapp/react-ab-test'

import { Flex, Scrollable } from "../../components";
import { DatePicker } from '../../../components';
import CategoriesList from './CategoriesList';

experimentDebugger.enable();
mixpanelHelper.enable();
// emitter.defineVariants('Expanded Datepicker', ['Expanded', 'Collapsed'], [0, 100]);

type SidebarProps = {
    onSelectDate: () => {},
    onSelectCategory: () => {}
};

class DatePickerField extends React.PureComponent {

    handleChange = value => {
        const { input: { onChange }, resetPage } = this.props;

        resetPage();
        onChange(value.format('YYYY-MM-DD'));
    };

    render() {
        const { input: { value }, expanded} = this.props;

        return <DatePicker from={value} to={value} range={false} onChange={this.handleChange} active={moment()} expanded={expanded}/>
    }
}

DatePickerField = connect(
    (state, props) => {
        return {}
    },
    (dispatch, props) => {
        return {
            resetPage: () => {
                dispatch(change('monitoring', 'page', 1));
            }
        }
    }
)(DatePickerField);

class CategoriesListField extends React.PureComponent {

    render() {
        const { input: { onChange }} = this.props;

        return <CategoriesList onClick={onChange}/>
    }
}

export default class extends React.PureComponent<SidebarProps> {

    render() {
        return <Scrollable flex direction="column">
            {/* @todo remove div */}
            <div>
                <Flex direction="column">
                    <Experiment ref="expanded-datepicker" name="Expanded Datepicker">
                        {/*<Variant name="Expanded">*/}
                            {/*<Field name="date" component={DatePickerField} from={moment()} to={moment()} range={false} active={moment()} expanded={true}/>*/}
                        {/*</Variant>*/}
                        <Variant name="Collapsed">
                            <Field name="date" component={DatePickerField} from={moment()} to={moment()} range={false} active={moment()} expanded={false}/>
                        </Variant>
                    </Experiment>
                    <Field name="category" component={CategoriesListField} />
                </Flex>
            </div>
        </Scrollable>
    }
}
