import * as React from "react";
import {Field} from 'redux-form';

import {Flex, Pill} from '../../components';
import ExportDatePickerField from '../Export/Filter/DatePickerFieldFilter';
import withFilterForm from './withFilterForm';

class Sidebar extends React.PureComponent {

    componentDidMount() {
        this.handleSearch();
    }

    handleSearch = () => {
        const {handleSubmit} = this.props;

        handleSubmit((values, dispatch, props) => this.props.onSubmit({
            button: 'filter',
            ...values
        }, dispatch, props))();
    };

    handleReset = async () => {
        const {reset, handleSubmit} = this.props;

        await reset();
        handleSubmit((values, dispatch, props) => this.props.onSubmit({
            button: 'filter',
            ...values
        }, dispatch, props))();
    };

    render() {
        return <Flex flex className="justify-content-between align-items-center">
            <Field name="date" component={ExportDatePickerField} onClose={this.handleSearch}/>
            <Pill color="white" backgroundColor="black" className="d-inline-flex" onClick={this.handleReset}>Vymazať Filtre</Pill>
        </Flex>
    }
}

export default class extends React.PureComponent {

    render() {
        const {form, query, initialValues} = this.props;

        const Component =  withFilterForm({
            form: form,
            initialValues: initialValues,
            query: query
        })(Sidebar);

        return <Component/>
    }
}


