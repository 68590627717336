import { combineReducers } from 'redux';

import { PROFILE_REQUEST, PROFILE_RECEIVE, PROFILE_ERROR } from '../actions/profile';

export default (state = {}, action) => {

    const isFetching = (state = false, action) => {
        switch (action.type) {
            case PROFILE_REQUEST:
                return true;
            case PROFILE_RECEIVE:
            case PROFILE_ERROR:
                return false;
            default:
                return state;
        }
    };

    const valid = (state = false, action) => {
        switch (action.type) {
            case PROFILE_RECEIVE:
                return true;
            default:
                return state;
        }
    };

    const data = (state = [], action) => {
        switch (action.type) {
            case PROFILE_RECEIVE:
                return action.payload;
            default:
                return state;
        }
    };

    return combineReducers({
        isFetching,
        valid,
        data
    })(state, action);
}