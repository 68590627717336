import { API_URL } from '../config';
import axios from 'axios';

export const POST_EXPORT_REQUEST = 'core/POST_EXPORT_REQUEST';
export const POST_EXPORT_RECEIVE = 'core/POST_EXPORT_RECEIVE';
export const POST_EXPORT_ERROR = 'core/POST_EXPORT_ERROR';

export default (client, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: POST_EXPORT_REQUEST
        });
        return axios.post(API_URL + `/client/${client}/exports`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getState().login.data
            }
        })
            .then(response => {
                dispatch({
                    type: POST_EXPORT_RECEIVE,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: POST_EXPORT_ERROR,
                    payload: error
                });
                throw error;
            })
        ;
    }
}
