//@flow

import * as React from 'react';
import moment from 'moment';
import { Popover, PopoverBody } from 'reactstrap';

import InputField from './InputField';
import { DatePicker } from '../../components';

export default class extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false
        }
    }

    handleChange = (value) => {
        const { input: { onChange }} = this.props;

        onChange(value.format('YYYY-MM-DD'));
        this.setState({open: false})
    };

    render() {
        const { input } = this.props;

        const props = {
            ...this.props,
            input: {
                ...input,
                value: input.value ? moment(input.value).format('DD. MM. YYYY') : moment().format('DD. MM. YYYY')
            },
        };

        return <div>
            <InputField {...props} onClick={() => this.setState({open: true})} autocomplete="off"/>
            <Popover target={props.id} placement="bottom" color="white" isOpen={this.state.open}>
                <PopoverBody>
                    <DatePicker onChange={this.handleChange} range={false} active={moment()} from={moment(input.value)} to={moment(input.value)}/>
                </PopoverBody>
            </Popover>

        </div>
    }
}