import * as React from 'react';
import { Flex, Icon } from "../../v2/components";
import styled from 'styled-components';

import {gallery} from "../../v2/variables";

const Navigation = styled(Flex).attrs({className: 'justify-content-between align-items-center mb-2'})`
    border-bottom: 1px solid ${gallery}
`;

export default class extends React.PureComponent {

    render() {
        const { active, onClickLeft, onClickRight } = this.props;

        return <Navigation>
            <Flex className="p-2 cursor-pointer" onClick={onClickLeft}>
                <Icon type="arrow_left" size="24"/>
            </Flex>
            <Flex flex className="text-capitalize font-weight-bold justify-content-center p-2">
                {active.format('MMMM')} {active.format('YYYY')}
            </Flex>
            <Flex className="p-2 cursor-pointer" onClick={onClickRight}>
                <Icon type="arrow_right" size="24"/>
            </Flex>
        </Navigation>
    }
}

