import gql from 'graphql-tag';

import {graphql} from "../../../../actions";

export default (name, variables) => graphql(name, 'analysis', gql`query Analysis(
    $client: Int!
    $from: String!
    $to: String!
) {
    analysis(
        client: $client, 
        from: $from, 
        to: $to, 
        field: "COUNT(a.id)", 
        buckets: [
            {
                bucket: "(SELECT sources.name AS id FROM articles JOIN sources ON articles.source_id = sources.id WHERE monitoring_date >= \\"${variables.from}\\" AND monitoring_date <= \\"${variables.to}\\" GROUP BY source_id ORDER BY COUNT(*) DESC LIMIT 20)", 
                field: "s.name"
            }
        ]
    ) {
        keys
        count
    }
}`, variables)