import * as React from 'react';
import moment from 'moment';

import Row from './Row';
import { DayCell } from "./Cell"

export default class extends React.PureComponent {

    render(): React.Node {
        return <Row>
            {[0, 1, 2, 3, 4, 5, 6].map(i => <DayCell key={i}>
                {moment().weekday(i).format('ddd')}
                </DayCell>)}
        </Row>
    }
}