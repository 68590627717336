//@flow

import * as React from 'react';

import { Pagination } from '../../../components';

export default class extends React.PureComponent {

    render() {
        const { input: { onChange, value }, ...rest} = this.props;

        return <Pagination onChange={onChange} page={value} {...rest}/>
    }

}