//@flow

import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Flex, SidebarTitle, SidebarItem } from '../../components';
import {stormyGray} from "../../variables";

const StyledLink = styled(Link)`
    color: ${stormyGray};
`;

class Item extends React.PureComponent {

    render() {
        const { location: {pathname}, to, children } = this.props;

        return <SidebarItem selected={pathname === to}><StyledLink to={to}>{children}</StyledLink></SidebarItem>
    }
}

Item = withRouter(Item);

export default class extends React.PureComponent {

    render() {
        return <Flex direction="column" className="p-3">
            <SidebarTitle>Analýza</SidebarTitle>
            <hr />
            <Item to="/analysis/category_rating">Polarita článkov podľa kategórií</Item>
            <Item to="/analysis/rating">Polarita článkov</Item>
            <Item to="/analysis/category_ave">Hodnota AVE podľa kategórií</Item>
            <Item to="/analysis/category_date">Počet článkov podľa kategórií</Item>
            <Item to="/analysis/source_date">Počet článkov podľa zdroja</Item>
            <Item to="/analysis/authors">Počet článkov podľa autora</Item>
        </Flex>
    }
}
