import * as React from 'react';
import { connect } from 'react-redux';
import { Field, change, formValueSelector } from 'redux-form';

import { Checkbox, Loader, SelectUnselectInvert, ArticleListItem, Flex, CategoryTitle, Scrollable } from "../../../components";
import OrderByField from './OrderByField';
import DirectionField from './DirectionField';
import Pagination from './Pagination';
import withFilterForm from '../withFilterForm';

type ResultCountProps = {
    count: number
};

class ResultCount extends React.PureComponent<ResultCountProps> {

    render() {
        const {selected, count} = this.props;

        return <strong className="mr-2">{selected} / {count}</strong>
    }
}

ResultCount = connect(
    (state, props) => {
        return {
            selected: formValueSelector('filter')(state, 'articles').length,
            count: state.graphql.filter?.data.meta?.count || 0
        }
    }
)(ResultCount);

const SelectArticles = connect(
    (state, props) => {
        return {
            selected: formValueSelector('filter')(state, 'articles'),
            articles: state.graphql.filter
        }
    },
    (dispatch, props) => {
        return {
            selectAll: ids => dispatch(change('filter', 'articles', ids)),
            unselectAll: () => dispatch(change('filter', 'articles', [])),
            invertSelection: ids => dispatch(change('filter', 'articles', ids)),
        }
    },
    (stateProps, dispatchProps, ownProps) => {
        return Object.assign({}, stateProps, dispatchProps, ownProps, {
            selectAll: () => {
                const selected = [
                    ...stateProps.selected,
                    ...stateProps.articles.data.data.map(item => item.id)
                ];
                dispatchProps.selectAll(selected.filter((item, index) => index === selected.indexOf(item)));
            },
            invertSelection: () => dispatchProps.invertSelection(stateProps.articles.data.data.filter(item => -1 === stateProps.selected.indexOf(item.id)).map(item => item.id))
        });
    }
)(SelectUnselectInvert);

class ExportArticleListItemsField extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            article: null
        }
    }

    componentDidMount() {
        const { monitoring, selected, onSelect} = this.props;

        let found = false;
        monitoring && monitoring.data.data.map(article => {
            if (selected === article.id) {
                found = true;
            }
            return null;
        });

        !found && monitoring?.data.data[0] && onSelect(monitoring.data.data[0].id);
    }

    componentDidUpdate() {
        const { monitoring, selected, onSelect} = this.props;

        let found = false;
        monitoring && monitoring.data.data.map(article => {
            if (selected === article.id) {
                found = true;
            }
            return null;
        });

        !found && monitoring?.data.data[0] && onSelect(monitoring.data.data[0].id);
    }

    handleSelect = id => {
        const { input: { onChange, value }} = this.props;

        let index = value.indexOf(id);
        if (-1 === index) {
            onChange([
                ...value,
                id
            ])
        } else {
            onChange([
                ...value.slice(0, index),
                ...value.slice(index + 1)
            ])
        }
    };

    render() {
        const { input, articles, offset, selected, onSelect } = this.props;

        if (!Array.isArray(input.value)) {
            input.value = [];
        }
        if (0 === Object.keys(articles).length) {
            return <div className="w-100 p-2">Nepodarilo sa najsť žiadne relevantné články</div>
        }

        let counter = 1;
        return <div>
            {Object.keys(articles).map((category, index) => {
                return <div key={index}>
                    <CategoryTitle>{category}</CategoryTitle>
                    <div>{articles[category].map(article => <ArticleListItem key={article.id}
                                                                             article={article}
                                                                             onSelect={onSelect}
                                                                             selected={selected === article.id}
                                                                             checkbox={<Checkbox checked={-1 !== input.value.indexOf(article.id)} onChange={() => this.handleSelect(article.id)}/>}
                                                                             title={ offset + counter++ + '. ' + article.title }
                    />)}
                    </div>
                </div>
            })}
        </div>
    }
}

ExportArticleListItemsField = connect(
    (state, props) => {
        let articles = {};
        const filter = state.graphql.filter?.data?.data?.forEach(article => {
           article.articlesTags.forEach(articleTag => {
               articleTag.tag.categoriesTags.forEach(categoryTag => {
                   articles = {
                       ...articles,
                       [categoryTag.category.name]: [
                           ...articles[categoryTag.category.name] || [],
                           article
                       ]
                   }
               })
           })
        });
        return {
            monitoring: state.graphql.filter,
            articles: articles
        }
    }
)(ExportArticleListItemsField);

class ArticlesList extends React.PureComponent {

    handleChange = () => {
        const {handleSubmit, onSubmit} = this.props;

        handleSubmit((values, dispatch, props) => onSubmit({
            button: 'filter',
            ...values
        }, dispatch, props))();
    };

    render(): React.Node {
        const {isFetching, offset, count, selected, onSelect} = this.props;

        return <Flex flex direction="column">
            <Flex className="justify-content-between align-items-center m-2">
                <Flex className="align-items-center">
                    <strong>Zoradiť:</strong>
                    <Field name="orderBy" component={OrderByField} onSelect={this.handleChange}/>
                    <Field name="direction" component={DirectionField} onSelect={this.handleChange} />
                </Flex>
                <Flex className="align-items-baseline">
                    <ResultCount/>
                    <SelectArticles size="20"/>
                </Flex>
            </Flex>
            <hr />
            <Scrollable direction="column" flex className="p-2">
                {isFetching ? <Flex flex><Loader size="lg"/></Flex>
                    : <Field name="articles" component={ExportArticleListItemsField} offset={offset} onSelect={onSelect} selected={selected}/>}
            </Scrollable>
            <Pagination count={count} />
        </Flex>
    }
}

ArticlesList = withFilterForm(ArticlesList);

export default connect(
    (state, props) => {
        return {
            isFetching: state.graphql.filter?.isFetching,
            count: state.graphql.filter?.data.meta?.count || 0,
            offset: state.graphql.filter?.data.meta?.offset || 0
        }
    },
)(ArticlesList);
