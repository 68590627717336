//@flow

import {type ComponentType} from 'react';
import styled from 'styled-components';

import type {colors} from "../variables";

type PillProps = {
    children: any,
    backgroundColor: colors,
    color: colors
}

const Pill: ComponentType<PillProps> = styled.div`
    background-color: ${props => props.backgroundColor || 'transparent'}
    border-radius: 5px;
    color: ${props => props.color || 'white'};
    padding: 3px 5px;
    cursor: pointer;
    
    &:hover {
        color: ${props => props.color || 'white'};
        text-decoration: none;
    }
`;

export default Pill;