import {reduxForm} from "redux-form";
import moment from "moment";
import {connect} from "react-redux";

export default ({form, query, initialValues}) => {

    return WrappedComponent => {

        WrappedComponent = reduxForm({
            form: form,
            initialValues: initialValues,
            onSubmit: (values, dispatch, props) => {
                props.getHistogram(moment(values.date.from), moment(values.date.to))
            }
        })(WrappedComponent);

        return connect(
            (state, props) => {
                return {
                    client: state.client,
                    histogram: state.graphql[form] && state.graphql[form].data ? state.graphql[form] : {data: []}
                }
            },
            (dispatch, props) => {
                return {
                    getHistogram: (client, from, to) => dispatch(query(form, client.data.id, from, to)),
                }
            },
            (stateProps, dispatchProps, ownProps) => {
                return Object.assign({}, ownProps, stateProps, dispatchProps, {
                    getHistogram: (from, to) => dispatchProps.getHistogram(stateProps.client, from, to)
                });
            }
        )(WrappedComponent);
    }

}
