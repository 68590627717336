import { API_URL } from '../config';
import axios from 'axios';

export const CATEGORIES_REQUEST = 'core/CATEGORIES_REQUEST';
export const CATEGORIES_RECEIVE = 'core/CATEGORIES_RECEIVE';
export const CATEGORIES_ERROR = 'core/CATEGORIES_ERROR';

export default client => {
    return (dispatch, getState) => {
        dispatch({
            type: CATEGORIES_REQUEST
        });
        return axios.get(API_URL + `/client/${client}/categories`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getState().login.data
            }
        })
            .then(response => {
                dispatch({
                    type: CATEGORIES_RECEIVE,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: CATEGORIES_ERROR,
                    payload: error
                });
                throw error;
            })
        ;
    }
}
