import { combineReducers } from 'redux';

import { LOGIN_REQUEST, LOGIN_RECEIVE, LOGIN_ERROR } from '../actions/login';
import { LOGOUT_REQUEST } from '../actions/logout';
import { GRAPHQL_RECEIVE } from '../actions/graphql';

export default (state = {}, action) => {

    const isFetching = (state = false, action) => {
        switch (action.type) {
            case LOGIN_REQUEST:
                return true;
            case LOGIN_RECEIVE:
            case LOGIN_ERROR:
                return false;
            default:
                return state;
        }
    };

    const valid = (state = false, action) => {
        switch (action.type) {
            case LOGIN_RECEIVE:
                return true;
            default:
                return state;
        }
    };

    const data = (state = null, action) => {
        switch (action.type) {
            case LOGIN_RECEIVE:
                return action.payload.token;
            case GRAPHQL_RECEIVE:
                if ('auth' === action.payload.name) {
                    return action.payload.data;
                }
                return state;
            case LOGOUT_REQUEST:
                return null;
            default:
                return state;
        }
    };

    return combineReducers({
        isFetching,
        valid,
        data
    })(state, action);
}