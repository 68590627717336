import * as React from "react";
import {Field} from 'redux-form';
import {PopoverBody, Popover} from 'reactstrap';

import {ExportTypeSelect} from '../../../../components';
import {Flex, Pill} from '../../../components';
import CategoriesFilter from './CategoriesFilter';
import SourcesFilter from './SourcesFilter';
import RatingFilter from './RatingFilter';
import MoreFilters from './MoreFilters';
import ExportDatePickerField from './DatePickerFieldFilter';
import withFilterForm from '../withFilterForm';
import {lightishBlue} from "../../../variables";

class ExportButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            opened: false
        }
    }

    handleToggle = () => {
        this.setState({opened: !this.state.opened});
    };

    render() {
        const {onSelect} = this.props;

        return <div className="d-inline-flex m-1">
            <Pill color="white" backgroundColor={lightishBlue} id="export-button" onClick={this.handleToggle}>Exportovať</Pill>
            <Popover target="export-button" placement="bottom" isOpen={this.state.opened} toggle={this.handleToggle}>
                <PopoverBody>
                    <Field name="export_setting" component={ExportTypeSelect} placeholder="Vyberte typ exportu"/>
                    <Pill color="white" backgroundColor="black" onClick={onSelect}>Exportovať</Pill>
                </PopoverBody>
            </Popover>
        </div>
    }

}

class Sidebar extends React.PureComponent {

    componentDidMount() {
        this.handleSearch();
    }

    handleSearch = () => {
        const {handleSubmit} = this.props;

        handleSubmit((values, dispatch, props) => this.props.onSubmit({
            button: 'filter',
            ...values
        }, dispatch, props))();
    };

    handleExport = () => {
        const {handleSubmit} = this.props;

        handleSubmit((values, dispatch, props) => this.props.onSubmit({
            button: 'export',
            ...values
        }, dispatch, props))();
    };

    handleReset = async () => {
        const {reset, handleSubmit} = this.props;

        await reset();
        handleSubmit((values, dispatch, props) => this.props.onSubmit({
            button: 'filter',
            ...values
        }, dispatch, props))();
    };

    render() {
        return <Flex flex className="align-items-center">
            <Field name="date" component={ExportDatePickerField} onClose={this.handleSearch}/>
            <Field name="categories" component={CategoriesFilter} onClose={this.handleSearch}/>
            <Field name="sources" component={SourcesFilter} onClose={this.handleSearch}/>
            <Field name="ratings" component={RatingFilter} onClose={this.handleSearch}/>
            <MoreFilters onClose={this.handleSearch}/>
            <Pill color="white" backgroundColor="black" className="d-inline-flex" onClick={this.handleReset}>Vymazať Filtre</Pill>
            <ExportButton onSelect={this.handleExport}/>
        </Flex>
    }
}

export default withFilterForm(Sidebar);
