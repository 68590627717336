import { combineReducers } from 'redux';

import {GRAPHQL_REQUEST, GRAPHQL_RECEIVE, GRAPHQL_ERROR} from '../actions/graphql';

export default (state = {}, action) => {

    const isFetching = (state = false, action) => {
        switch (action.type) {
            case GRAPHQL_REQUEST:
                return true;
            case GRAPHQL_RECEIVE:
            case GRAPHQL_ERROR:
                return false;
            default:
                return state;
        }
    };

    const valid = (state = false, action) => {
        switch (action.type) {
            case GRAPHQL_RECEIVE:
                return true;
            default:
                return state;
        }
    };

    const data = (state = [], action) => {
        switch (action.type) {
            case GRAPHQL_RECEIVE:
                return action.payload.data;
            default:
                return state;
        }
    };

    switch (action.type) {
        case GRAPHQL_REQUEST:
        case GRAPHQL_RECEIVE:
        case GRAPHQL_ERROR:
            return {
                ...state,
                [action.payload.name]: combineReducers({isFetching, valid, data})(state[action.payload.name], action)
            };
        default:
            return state;
    }
}