import styled from "styled-components";

import Flex from './Flex';
import {dustyGray25, stormyGray} from "../variables";

export default styled(Flex).attrs({className: 'justify-content-between cursor-pointer py-1 px-2'})`
    font-size: 14px;
    font-weight: ${props => props.selected ? '700': '600'};
    color: ${stormyGray}
    background-color: ${props => props.selected ? dustyGray25 : 'transparent'}
`;

