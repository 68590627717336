import React from 'react';
import { compose, createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import { Router, Route, Switch, Redirect } from 'react-router';

import reducers from './reducers';
import { Login } from './pages';
import { Monitoring, Export, Archive, Clients, Logout, RatingSummary, RatingCategorySummary, AveCategorySummary, CategoryDateSummary, SourceDateSummary, AuthorsSummary, Help, Auth } from './v2/pages';
import 'moment/locale/sk';

const persistedReducer = persistReducer({ key: 'root', blacklist: ['ui', 'router'], storage }, reducers);

const store = createStore(persistedReducer, undefined, compose(applyMiddleware(thunk)));

const persistor = persistStore(store);

export default () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router history={createHistory()}>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/auth/:token" component={Auth}/>
                    <Route path="/" render={props => {
                        if (!store.getState().login.data) {
                            props.history.push('/login');
                            return null;
                        }
                        return <Switch>
                            <Route path="/clients" component={Clients} />
                            <Route path="/logout" component={Logout}/>
                            <Route path="/" render={props => {
                                if (!store.getState().client.data) {
                                    props.history.push('/clients');
                                    return null;
                                }
                                return <>
                                    <Switch>
                                        <Route path="/monitoring" component={Monitoring} />
                                        <Route path="/export" component={Export} />
                                        <Route path="/analysis/category_rating" component={RatingCategorySummary} />
                                        <Route path="/analysis/rating" component={RatingSummary} />
                                        <Route path="/analysis/category_ave" component={AveCategorySummary} />
                                        <Route path="/analysis/category_date" component={CategoryDateSummary} />
                                        <Route path="/analysis/source_date" component={SourceDateSummary} />
                                        <Route path="/analysis/authors" component={AuthorsSummary} />
                                        <Redirect to="/monitoring" />
                                    </Switch>
                                    <Route render={({match, location, history}) => 'help' === location?.state?.modal && <Help/>}/>
                                </>
                            }}>
                            </Route>
                        </Switch>
                    }}>
                    </Route>
                </Switch>
            </Router>
        </PersistGate>
    </Provider>
);

