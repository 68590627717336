//@flow

import styled from 'styled-components';

export default styled.div.attrs({className: 'm-1'})`
    width: 21px;
    height: 21px;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
`;
