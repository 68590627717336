import { API_URL } from '../../../../config';
import {authorized} from "../../../../actions/axios";

export const HISTOGRAM_REQUEST = 'core/HISTOGRAM_REQUEST';
export const HISTOGRAM_RECEIVE = 'core/HISTOGRAM_RECEIVE';
export const HISTOGRAM_ERROR = 'core/HISTOGRAM_ERROR';
export const HISTOGRAM_CLEAR = 'core/HISTOGRAM_CLEAR';

export default (name, client, from, to, fields, aggregate, orderBy, direction, limit, offset) => {
    return (dispatch, getState) => {
        dispatch({
            type: HISTOGRAM_REQUEST,
            payload: {
                name: name
            }
        });
        return authorized(getState()).get(API_URL + `/client/${client}/analysis/histogram`, {
            params: {
                from: from.format('YYYY-MM-DD'),
                to: to.format('YYYY-MM-DD'),
                fields: fields,
                aggregate: aggregate,
                orderBy: orderBy,
                direction: direction,
                limit: limit,
                offset: offset
            }
        })
            .then(response => {
                dispatch({
                    type: HISTOGRAM_RECEIVE,
                    payload: {
                        name: name,
                        data: response.data
                    }
                });
            })
            .catch(error => {
                dispatch({
                    type: HISTOGRAM_ERROR,
                    payload: {
                        name: name,
                        error: error
                    }
                });
                // throw error;
            })
        ;
    }
}

export const clear = name => {
    return {
        type: HISTOGRAM_CLEAR,
        payload: {
            name: name
        }
    };
};
