//@flow

import * as React from "react";
import type { ArticleType } from './types';

type ArticleHeaderProps = {
    article: ArticleType
}

export default class extends React.PureComponent<ArticleHeaderProps> {

    render() {
        const { article } = this.props;

        return <dl className="mb-1">
            <dt className="float-left">Zdroj:</dt>
            <dd className="float-left ml-1">{article.source.name}</dd>
            <dt className="float-left ml-3">Autor:</dt>
            <dd className="float-left ml-1">{article.author}</dd>
            <dt className="float-left ml-3">Servis:</dt>
            <dd className="float-left ml-1">{article.service}</dd>
            <dt className="float-left ml-3">Strana:</dt>
            <dd className="float-left ml-1">{article.page}</dd>
            <dt className="float-left ml-3">Hodnota AVE:</dt>
            <dd className="float-left ml-1">{parseFloat(article.ave_value).toFixed(2)} &euro;</dd>
        </dl>
    }

}
