import * as React from 'react';
import { connect } from 'react-redux';

import { Select2Field } from '../components';
import { exportTypes } from '../actions';

class ExportTypeSelect extends React.PureComponent {

    static defaultProps = {
        placeholder: false
    };

    componentDidMount() {
        const { getExportTypes } = this.props;

        getExportTypes();
    }

    get exportTypes() {
        const { exportTypes } = this.props;

        return exportTypes.items.map(item => {
            return { value: item.id, label: item.name }
        });
    }

    get styles() {
        return {
            placeholder: (provided, state) => ({
                ...provided,
                position: 'static',
                top: 'auto',
                transform: 'none'
            })
        }
    }

    render() {
        const { getExportTypes, exportTypes, client, placeholder, meta, ...props } = this.props;

        return <div>
            {meta.error && <span>{meta.error}</span>}
            <Select2Field {...props} meta={meta} placeholder={placeholder} options={this.exportTypes} styles={this.styles}/>
        </div>
    }
}

export default connect(
    (state, props) => {
        return {
            exportTypes: state.exportTypes,
            client: state.client
        }
    },
    (dispatch, props) => {
        return {
            getExportTypes: client => dispatch(exportTypes(client.data.id))
        }
    },
    (stateProps, dispatchProps, ownProps) => {
        return Object.assign({}, ownProps, stateProps, dispatchProps, {
            getExportTypes: () => dispatchProps.getExportTypes(stateProps.client)
        });
    }
)(ExportTypeSelect);