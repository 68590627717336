import * as React from "react";
import 'react-vis/dist/style.css';
import moment from 'moment';

import connect from './connect';
import Graph from './BarSeriesGraph';
import {categoryDateSummary} from "./queries";

class CategoryDateSummaryGraph extends React.PureComponent {

    get results() {
        const { histogram: { data }} = this.props;

        let result = {};
        data.forEach(item => {
            result = {
                ...result,
                [item.keys[0]]: {
                    ...result?.[item.keys[0]],
                    [item.keys[1]]: item.count
                }
            };
        });

        return result;
    };

    get legend() {
        const results = this.results;
        
        return Object.keys(results?.[Object.keys(results)[0]] || {});
    }

    get series() {
        const results = this.results;

        return Object.keys(results).map(key => ({name: key, data: Object.values(results[key])}));
    }

    render() {
        return <Graph legend={this.legend} series={this.series} title="Počet článkov podľa kategórií"/>
    }
}

export default connect({
    name: 'categoryDate',
    query: (name, client, from, to) => categoryDateSummary(name, {
        client: client,
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD')
    }),
    initialValues: {
        date: {
            from: moment().subtract(1, 'M').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD')
        }
    }
})(CategoryDateSummaryGraph);
