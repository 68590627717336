import * as React from 'react';
import classnames from 'classnames';

export default class extends React.PureComponent {

    render() {
        const { label, className, children, ...props } = this.props;

        return <div className={classnames("chip d-flex px-3 py-2 justify-content-between align-items-baseline cursor-pointer", className)} {...props}>
            <span>{label}</span>
            {children}
        </div>
    }
}
