import { combineReducers } from 'redux';

import { ARTICLE_REQUEST, ARTICLE_RECEIVE, ARTICLE_ERROR } from '../actions/article';

export default (state = {}, action) => {

    const isFetching = (state = false, action) => {
        switch (action.type) {
            case ARTICLE_REQUEST:
                return true;
            case ARTICLE_RECEIVE:
            case ARTICLE_ERROR:
                return false;
            default:
                return state;
        }
    };

    const valid = (state = false, action) => {
        switch (action.type) {
            case ARTICLE_RECEIVE:
                return true;
            default:
                return state;
        }
    };

    const data = (state = null, action) => {
        switch (action.type) {
            case ARTICLE_RECEIVE:
                return action.payload;
            default:
                return state;
        }
    };

    return combineReducers({
        isFetching,
        valid,
        data
    })(state, action);
}