import { combineReducers } from 'redux';

import {HISTOGRAM_REQUEST, HISTOGRAM_RECEIVE, HISTOGRAM_ERROR, HISTOGRAM_CLEAR} from '../actions/histogram';

export default (state = {}, action) => {

    const isFetching = (state = false, action) => {
        switch (action.type) {
            case HISTOGRAM_REQUEST:
                return true;
            case HISTOGRAM_RECEIVE:
            case HISTOGRAM_ERROR:
            case HISTOGRAM_CLEAR:
                return false;
            default:
                return state;
        }
    };

    const valid = (state = false, action) => {
        switch (action.type) {
            case HISTOGRAM_RECEIVE:
                return true;
            default:
                return state;
        }
    };

    const items = (state = [], action) => {
        switch (action.type) {
            case HISTOGRAM_CLEAR:
                return [];
            case HISTOGRAM_RECEIVE:
                return action.payload.data;
            default:
                return state;
        }
    };

    switch (action.type) {
        case HISTOGRAM_REQUEST:
        case HISTOGRAM_RECEIVE:
        case HISTOGRAM_ERROR:
            return {
                ...state,
                [action.payload.name]: combineReducers({isFetching, valid, items})(state[action.payload.name], action)
            };
        case HISTOGRAM_CLEAR:
            const {[action.payload.name]: name, ...rest} = state;
            return rest;
        default:
            return state;
    }

}