import * as React from "react";
import 'react-vis/dist/style.css';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';

import {Flex} from '../../components';

if (typeof Highcharts === 'object') {
    HighchartsExporting(Highcharts)
}

export default class  extends React.PureComponent {

    render() {
        const {title, legend, series} = this.props;

        return <Flex>
            <HighchartsReact highcharts={Highcharts} options={{
                title: {text: title},
                chart: {
                    type: 'pie',
                    width: 800,
                    height: 500
                },
                xAxis: {
                    categories: legend
                },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                },
                series: [{
                    data: series
                }],
                plotOptions: {
                    pie: {
                        innerSize: 180
                    }
                }
            }}/>
        </Flex>
    }
}
