//@flow

import * as React from 'react';
import styled from 'styled-components';

import {SidebarItem} from "../../../components";
import {stormyGray} from "../../../variables";

type CategoriesListItemProps = {
    id: number,
    name: string,
    daily: number,
    done: number,
    selected: boolean,
    onClick: (id: number) => void
}

const DailyCount = styled.span`
    color: ${stormyGray};
    font-weight: bold;
`;

const TotalCount = styled.span`
    color: ${stormyGray};
    font-weight: 500;
`;

const Counter = styled.span`
    white-space: nowrap;
`;

export default class extends React.PureComponent<CategoriesListItemProps> {

    handleClick = () => {
        const {id, onClick} = this.props;

        onClick(id);
    };

    render(): React.Node {
        const {name, done, daily = 0, selected } = this.props;

        return <SidebarItem onClick={this.handleClick} selected={selected}>
            <span>{name}</span>
            <Counter>
                <DailyCount>{daily} / </DailyCount><TotalCount>{done}</TotalCount>
            </Counter>
        </SidebarItem>
    }
}