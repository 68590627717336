import * as React from 'react';
import { Select2Field } from '../components';

export default class extends React.PureComponent {

    render() {
        return <Select2Field {...this.props} options={[
            {value: 0, label: 'Agrosektor'},
            {value: 1, label: 'Armáda'},
            {value: 2, label: 'Auto moto'},
            {value: 3, label: 'Banky, poisťovne, investovanie'},
            {value: 4, label: 'Cestovanie'},
            {value: 5, label: 'Drevo, nábytok'},
            {value: 6, label: 'Ekonomika, financie, právo'},
            {value: 30, label: 'Energetika'},
            {value: 7, label: 'Enviro'},
            {value: 8, label: 'Hotely, reštaurácie, kaviarne'},
            {value: 9, label: 'IT servery, mobilná komunikácia, foto, digi, vvalueeo, SW, HW'},
            {value: 29, label: 'Komunalne spravy'},
            {value: 11, label: 'Magazíny, lifestyle, hobby'},
            {value: 12, label: 'Marketing, ľudské zdroje a retail'},
            {value: 13, label: 'PR správy'},
            {value: 14, label: 'Programový sprievodca'},
            {value: 15, label: 'Reality a bývanie'},
            {value: 16, label: 'Regionálne spravodajstvo'},
            {value: 17, label: 'Seniori'},
            {value: 18, label: 'Služby, mapy, cestovné poriadky'},
            {value: 19, label: 'Spoločnosť, politika, ekonomika<'},
            {value: 20, label: 'Spravodajstvo'},
            {value: 21, label: 'Stavebníctvo'},
            {value: 22, label: 'TV, rádiá'},
            {value: 23, label: 'Voľný čas a zábava'},
            {value: 24, label: 'Weblog'},
            {value: 25, label: 'Zdravie'},
            {value: 26, label: 'Časopis pre tínedžerov'},
            {value: 27, label: 'Šport'},
            {value: 28, label: 'Ženy a maminy'},
        ]}/>
    }
}
