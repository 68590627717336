import { API_URL } from '../config';
import axios from 'axios';

export const MONITORINGS_REQUEST = 'core/MONITORINGS_REQUEST';
export const MONITORINGS_RECEIVE = 'core/MONITORINGS_RECEIVE';
export const MONITORINGS_ERROR = 'core/MONITORINGS_ERROR';

export default (client, year, month) => {
    return (dispatch, getState) => {
        dispatch({
            type: MONITORINGS_REQUEST
        });
        return axios.get(API_URL + `/client/${client}/monitorings/${year}/${month}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getState().login.data
            }
        })
            .then(response => {
                dispatch({
                    type: MONITORINGS_RECEIVE,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: MONITORINGS_ERROR,
                    payload: error
                });
                throw error;
            })
        ;
    }
}
