//@flow

import * as React from 'react';
import FontAwesome from "react-fontawesome";
import type { FieldProps } from 'redux-form';

type DirectionFieldProps = FieldProps & {
    onSelect: () => {}
}

export default class extends React.PureComponent<DirectionFieldProps> {

    handleChange = async direction => {
        const {onSelect, input: {onChange}} = this.props;

        await onChange(direction);
        onSelect();
    };

    render() {
        const {input: {value}} = this.props;

        if ('desc' === value) {
            return <FontAwesome className="fal" name="chevron-down" size="lg" onClick={() => this.handleChange('asc')}/>
        } else {
            return <FontAwesome className="fal" name="chevron-up" size="lg" onClick={() => this.handleChange('desc')}/>
        }
    }
}