import { API_URL } from '../config';
import axios from 'axios';

export const PATCH_ARTICLE_REQUEST = 'core/PATCH_ARTICLE_REQUEST';
export const PATCH_ARTICLE_RECEIVE = 'core/PATCH_ARTICLE_RECEIVE';
export const PATCH_ARTICLE_ERROR = 'core/PATCH_ARTICLE_ERROR';

export default (client, article, rating) => {
    return (dispatch, getState) => {
        dispatch({
            type: PATCH_ARTICLE_REQUEST
        });
        return axios.patch(API_URL + `/client/${client}/article/${article}/rate`, {
            article: {
                rating: rating
            }
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getState().login.data
            }
        })
            .then(response => {
                dispatch({
                    type: PATCH_ARTICLE_RECEIVE,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: PATCH_ARTICLE_ERROR,
                    payload: error
                });
                throw error;
            })
        ;
    }
}
