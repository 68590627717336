import { API_URL } from '../config';
import axios from 'axios';

export const EXPORT_TYPES_REQUEST = 'core/EXPORT_TYPES_REQUEST';
export const EXPORT_TYPES_RECEIVE = 'core/EXPORT_TYPES_RECEIVE';
export const EXPORT_TYPES_ERROR = 'core/EXPORT_TYPES_ERROR';

export default client => {
    return (dispatch, getState) => {
        dispatch({
            type: EXPORT_TYPES_REQUEST
        });
        return axios.get(API_URL + `/client/${client}/export_settings`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getState().login.data
            }
        })
            .then(response => {
                dispatch({
                    type: EXPORT_TYPES_RECEIVE,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: EXPORT_TYPES_ERROR,
                    payload: error
                });
                throw error;
            })
        ;
    }
}
