import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import classnames from 'classnames';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import { formValueSelector } from 'redux-form';

import { clients } from '../../actions/index';
import changeClient from '../../actions/change-client';
import { SingleColumnLayout } from '.';
import { Scrollable, FilterSearchField, Tooltip } from '../components';
import {gallery54} from "../variables";

const ClientCardRow = ({ children, className, ...rest }) => {
    return <div className={classnames(className, 'd-flex justify-content-between')} {...rest}>{children}</div>
};

const ClientCard = styled.div.attrs({className: 'm-2 float-left'})`
    width: 250px;
`;

const ClientCardRowGrey = styled(ClientCardRow)`
    color: #888;
`;

const Content = styled(Scrollable)`
    background-color: ${gallery54};
`;

const ClientCardHeader = styled.div`
    background-color: #fff;
    border-radius: 10px
`;

const Logo = styled.img`
    mix-blend-mode: multiply;
    height: 20px
`;

const Tag = styled.span`
    background-color: #ea7a7a;
    border-radius: 20px;
    color: white;
`;

class Client extends React.PureComponent {

    handleSelectClient = () => {
        const { onChangeClient, client, history } = this.props;

        onChangeClient(client);

        history.push('/monitoring')
    };

    get logo() {
        const { client } = this.props;

        return `https://cdn.mediabase.sk/media/cache/resolve/thumb/uploads/logo/${client.logo && client.logo.file.substring(0, 2)}/${client.logo.file}`
    }

    get clientLogo() {
        const { client } = this.props;

        return client.logo ? <Logo src={this.logo} alt={client.name} className="float-left"/>
            : <span className="font-weight-bold">{client.name}</span>
    }

    get header() {
        const { client } = this.props;

        return <ClientCardHeader id={`client-${client.id}`} className="d-flex px-3 py-2 align-items-center justify-content-between cursor-pointer" onClick={this.handleSelectClient}>
            {this.clientLogo}
            <span className="d-flex align-items-center">
                <Tag className="py-1 px-2 mx-2 font-weight-bold">{client.done_count}</Tag>
                <FontAwesome name="arrow-circle-right" className="fal" size="2x"/>
            </span>
            <Tooltip target={`client-${client.id}`} color="black">{client.name}</Tooltip>
        </ClientCardHeader>
    }

    get statistics() {
        const { client } = this.props;

        return <div className="px-3 py-2">
            <ClientCardRowGrey>
                <span>Nepracované</span>
                <span>{client.unprocessed_count}</span>
            </ClientCardRowGrey>
            <ClientCardRowGrey>
                <span>Spracováva sa</span>
                <span>{client.inprocess_count}</span>
            </ClientCardRowGrey>
            <ClientCardRowGrey>
                <span>Irrelevantné</span>
                <span>{client.irrelevant_count}</span>
            </ClientCardRowGrey>
            <ClientCardRowGrey>
                <span>Spracované</span>
                <span>{client.done_count}</span>
            </ClientCardRowGrey>
        </div>
    }

    get summary() {
        const { client } = this.props;

        return <ClientCardRow className="font-weight-bold px-3 py-2">
            <span>Celkový počet</span>
            <span>{client.article_count}</span>
        </ClientCardRow>
    }

    render() {
        return <ClientCard>
            {this.header}
            {this.statistics}
            <hr className="m-0 mx-2" />
            {this.summary}
        </ClientCard>
    }
}

Client = connect(
    null,
    (dispatch, props) => {
        return {
            onChangeClient: client => dispatch(changeClient(client))
        }
    }
)(Client);

Client = withRouter(Client);

class Clients extends React.PureComponent {

    componentWillMount() {
        const { getClients } = this.props;

        getClients();
    }

    render(): React.Node {
        const { filter, clients: { items }} = this.props;

        return <SingleColumnLayout header={<FilterSearchField/>}>
            <Content flex className="flex-wrap">
            {items.map(item => {
                return (!filter || -1 !== item.name.toLowerCase().indexOf(filter.toLowerCase())) && <Client key={item.id} client={item} />
            })}
            </Content>
        </SingleColumnLayout>
    }
}

Clients = connect(
    (state, props) => {
        return {
            clients: state.clients,
            filter: formValueSelector('filter')(state, 'query')
        }
    },
    (dispatch, props) => {
        return {
            getClients: () => dispatch(clients())
                .then(response => {
                    if (1 === response.payload.length) {
                        dispatch(changeClient(response.payload[0]));

                        props.history.push('/monitoring');
                    }
                })
        }
    }
)(Clients);

export default withRouter(Clients);