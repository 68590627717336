//@flow

import * as React from "react";

import { MainLayout } from '.';
import { Content } from '../components';

type SingleColumnLayoutProps = {
    children: React.Node
}

export default class extends React.PureComponent<SingleColumnLayoutProps> {

    render(): React.Node {
        const { children, ...props } = this.props;

        return <MainLayout {...props}>
            <Content flex>
                {children}
            </Content>
        </MainLayout>
    }
}
