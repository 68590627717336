import * as React from 'react';

type ArrayFieldProps = {
    input: object,
    option: number,
    label: string,
    component: () => void
}

export default class extends React.PureComponent<ArrayFieldProps> {

    handleChange = () => {
        const { input, option } = this.props;

        const index = input.value.indexOf(option);
        if (-1 === index) {
            input.onChange(input.value.concat(option));
        } else {
            input.onChange([
                ...input.value.slice(0, index),
                ...input.value.slice(index + 1)
            ]);
        }
    };

    get checked() {
        const { input, option } = this.props;

        return -1 !== input.value.indexOf(option);
    }

    render(): React.Node {
        const { input, label, component } = this.props;

        if (!Array.isArray(input.value)) {
            input.value = [];
        }

        const Tag = component;

        return <Tag checked={this.checked} onChange={this.handleChange}>{label}</Tag>;
    }
}
