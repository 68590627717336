//@flow

import * as React from 'react';
import FontAwesome from 'react-fontawesome';
import {Link} from "react-router-dom";

export default class extends React.PureComponent {

    render() {
        return <Link to="/logout" id="button-logout" className="text-white">
            <FontAwesome name="sign-out" size="2x" className="fal p-2">Logout</FontAwesome>
            <div>Odhlásiť</div>
        </Link>
    }
}
