import * as React from 'react';

import ExportArticleList from './ExportArticleList';
import { Flex, Article } from "../../../components";
import styled from "styled-components";
import Scrollable from "../../../components/Scrollable";
import {gallery09, gallery25} from "../../../variables";

const StyledList = styled(Flex)`
    background-color: ${gallery25}
`;

const StyledContent = styled(Scrollable)`
    background-color: ${gallery09}
`;

type ArticlesProps = {

}

type ArticlesState = {
    id: ?number
}

export default class extends React.Component<ArticlesProps, ArticlesState> {

    constructor(props: ArticlesProps) {
        super(props);

        this.state = {
            id: null
        }
    }

    handleSelect = (id: number) => {
        this.setState({
            id: id
        })
    };

    render() {
        return <Flex flex>
            <StyledList flex={2} direction="column">
                <ExportArticleList onSelect={this.handleSelect} selected={this.state.id}/>
            </StyledList>
            <StyledContent flex={3} direction="column">
                <Article id={this.state.id}/>
            </StyledContent>
        </Flex>
    }
}