import gql from 'graphql-tag';

import {graphql} from "../actions";

export default variables => graphql('filter', 'filter', gql`query Filter(
    $client: Int!
    $query: String
    $from: String!
    $to: String!
    $dateType: String!
    $categories: [Int!]
    $sources: [Int!]
    $ratings: [Int!]
    $type: Int
    $scope: Int
    $periodicity: Int
    $aveFrom: Float
    $aveTo: Float
    $orderBy: String = "_score"
    $direction: String = "DESC"
    $offset: Int = 0
    $limit: Int = 30
) {
    filter(
        client: $client,
        query: $query,
        from: $from,
        to: $to,
        dateType: $dateType,
        categories: $categories,
        sources: $sources,
        ratings: $ratings,
        periodicity: $periodicity,
        type: $type, 
        scope: $scope,
        aveFrom: $aveFrom,
        aveTo: $aveTo,
        orderBy: $orderBy, 
        direction: $direction,
        offset: $offset,
        limit: $limit
    ) {
        meta {
            count
            offset
            limit
        }
        data {
            id
            title
            keywords
            rating
            date
            service
            author
            source {
                id
                name
            }
            clipping {
                id
                url
            }
            articlesTags {
                tag {
                    categoriesTags {
                        category {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
}`, variables)