//@flow

import * as React from "react";

import ExportArticlesList from './ExportArticlesList';
import {SingleColumnLayout} from '..';
import Filter from './Filter';
import { FilterSearchField }from '../../components';

export default class extends React.PureComponent {

    render(): React.Node {
        return <SingleColumnLayout sidebar={null} header={<FilterSearchField/>} toolbar={<Filter/>}>
            <ExportArticlesList/>
        </SingleColumnLayout>
    }
}
