import * as React from "react";

import { Checkbox } from '../../../v2/components';
import ArrayField from '../../../fields/ArrayField';

export default class extends React.PureComponent {

    render() {
        const { input } = this.props;

        const statuses = [
            {id: 0, name: 'Neohodnotené'},
            {id: 1, name: 'Negatívne'},
            {id: 2, name: 'Neutrálne'},
            {id: 3, name: 'Pozitívne'}
        ];

        return <div>
            {statuses.map(status => <ArrayField key={status.id} input={input} label={status.name} option={status.id} component={Checkbox}>{status.name}</ArrayField>)}
        </div>
    }
}