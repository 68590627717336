import {connect} from 'react-redux';
import {reduxForm} from "redux-form";
import moment from "moment";
import {withRouter} from 'react-router';

import monitoring from "../../../actions/monitoring";
import histogram from "../Analysis/actions/histogram";

export default WrappedComponent => {

    WrappedComponent = reduxForm({
        form: 'monitoring',
        initialValues: {
            category: null,
            date: moment().format('YYYY-MM-DD'),
            page: 1,
            query: ''
        },
        onSubmit: (values, dispatch, props) => dispatch(monitoring(props.client.data.id, values.category, values.date, values.query)),
        onChange: (values, dispatch, props, previousValues) => {
            if (values.date !== previousValues.date) {
                props.getDailyCount(values.date);
            }
            return props.getMonitoring(values.category, values.date, values.query, values.page)
        }
    })(WrappedComponent);

    WrappedComponent = connect(
        (state, props) => {
            return {
                client: state.client
            }
        },
        (dispatch, props) => {
            return {
                getDailyCount: (client, date) => dispatch(histogram('dailyCounts', client, moment(date), moment(date), ['category.id'], ['COUNT(article.id) AS count'])),
                getMonitoring: (client, category, date, query, page) => dispatch(monitoring(client, category, date, query, (page - 1) * 30))
            }
        },
        (stateProps, dispatchProps, ownProps) => {
            return Object.assign({}, ownProps, stateProps, {
                getDailyCount: (date) => dispatchProps.getDailyCount(stateProps.client.data.id, moment(date), moment(date), ['category.id'], ['COUNT(article.id) AS count']),
                getMonitoring: (category, date, query, page) => dispatchProps.getMonitoring(stateProps.client.data.id, category, date, query, page)
            });
        }
    )(WrappedComponent);

    return withRouter(WrappedComponent);
}
