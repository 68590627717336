import { combineReducers } from 'redux';

import { EXPORTS_REQUEST, EXPORTS_RECEIVE, EXPORTS_ERROR } from '../actions/exports';

export default (state = {}, action) => {

    const isFetching = (state = false, action) => {
        switch (action.type) {
            case EXPORTS_REQUEST:
                return true;
            case EXPORTS_RECEIVE:
            case EXPORTS_ERROR:
                return false;
            default:
                return state;
        }
    };

    const valid = (state = false, action) => {
        switch (action.type) {
            case EXPORTS_RECEIVE:
                return true;
            default:
                return state;
        }
    };

    const items = (state = [], action) => {
        switch (action.type) {
            case EXPORTS_RECEIVE:
                return action.payload;
            default:
                return state;
        }
    };

    return combineReducers({
        isFetching,
        valid,
        items
    })(state, action);
}