//@flow

import * as React from "react";

import ArticlesList from "./Articles";
import { DoubleColumnLayout } from "..";
import Sidebar from "./Sidebar";
import Toolbar from './Toolbar';
import moment from "moment";

import {SearchField} from '../../components';
import withMonitoringForm from './withMonitoringForm';

type MonitoringProps = {
    getMonitoring: () => {},
}

const MonitoringSearchField = withMonitoringForm(SearchField);

class Monitoring extends React.Component<MonitoringProps> {

    componentDidMount() {
        const { getDailyCount, getMonitoring } = this.props;

        getDailyCount(moment().format('YYYY-MM-DD'));
        getMonitoring(null, moment().format('YYYY-MM-DD'), null, 1);
    }

    render(): React.Node {
        return <DoubleColumnLayout header={<MonitoringSearchField/>} sidebar={<Sidebar/>} toolbar={<Toolbar/>}>
            <ArticlesList/>
        </DoubleColumnLayout>
    }
}

export default withMonitoringForm(Monitoring);

