import * as React from 'react';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';

import { Pill } from '../../../components';
import {seagull} from "../../../variables";
import Flex from "../../../components/Flex";

type FilterProps = {
    name: string,
    label: string,
    active: boolean,
    header: React.Node,
    onClose: () => void
}

export default class extends React.Component<FilterProps> {

    static defaultProps = {
        active: false,
        header: null
    };

    constructor(props) {
        super(props);

        this.state = {
            opened: false
        }
    }

    handleToggle = () => {
        if (this.state.opened) {
            this.handleClose();
        }

        this.setState({ opened: !this.state.opened });
    };

    handleClose = () => {
        const { onClose } = this.props;

        onClose();
    };

    get active() {
        const { active } = this.props;

        return active ? seagull : "white";
    }

    get footer(): React.Node {
        const { onClear } = this.props;

        return <Flex flex className="justify-content-between">
            <Pill color="white" backgroundColor="black" onClick={onClear}>Vymazať</Pill>
            <Pill color="white" backgroundColor="black" onClick={this.handleToggle}>Potvrdiť</Pill>
        </Flex>
    }

    render(): React.Node {
        const { label, name, header, children } = this.props;

        return <div className="d-inline-flex m-1">
            <Pill id={name} onClick={this.handleToggle} backgroundColor={this.active} color="black">
                <span className="mx-4 my-2">{label}</span>
            </Pill>
            <Popover target={name} placement="bottom" isOpen={this.state.opened} toggle={this.handleToggle}>
                {header && <PopoverHeader>{header}</PopoverHeader>}
                <PopoverBody>
                    {children}
                    {this.footer}
                </PopoverBody>
            </Popover>
        </div>
    }
}
