//@flow

import * as React from 'react'
import styled from 'styled-components';

const Logo = styled.img.attrs({className: 'mx-3'})`
    mix-blend-mode: multiply;
    height: 40px;
`;

export default class extends React.PureComponent {

    get logo() {
        const { src } = this.props;

        return `https://cdn.mediabase.sk/media/cache/resolve/thumb/uploads/logo/${src.substring(0, 2)}/${src}`
    }

    render() {
        return <Logo src={this.logo} alt=""/>
    }
}