import * as React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';

import {Flex, Keywords, RatingNeutral, RatingNegative, RatingPositive, Clipping} from '.';
import styled from "styled-components";
import {gallery, gallery54, gray, wildSand} from "../variables";

const Title = styled.h3.attrs({className: 'cursor-pointer'})``;

const HeaderInfo = styled.span`
    color: ${gray};
    font-size: 13px;
`;

const Strong600 = styled.strong`
    font-weight: 600
`;

const Checkbox = styled(Flex).attrs({
    className: 'p-1 justify-content-around align-items-center'
})`
    background-color: ${wildSand};
    width: 40px;
`;

const ArticleListItem = styled(Flex).attrs({className: 'justify-content-between'})`
    background-color: ${props => props.selected ? gallery54 : 'transparent'};
    border-bottom: 1px solid ${gallery}
`;

type ArticlesListItemProps = {
    article: any,
    onSelect: () => void
}

class Ratings extends React.PureComponent {

    render() {
        const {rating} = this.props;

        return <div>
            {3 === rating && <RatingPositive/>}
            {2 === rating && <RatingNeutral/>}
            {1 === rating && <RatingNegative/>}
        </div>
    }
}

class Header extends React.PureComponent {

    render() {
        const {article} = this.props;

        return <div>
            <HeaderInfo>Autor: <Strong600>{article.author}</Strong600></HeaderInfo>
            <HeaderInfo className="ml-2">Zdroj: <Strong600>{article.source.name}</Strong600></HeaderInfo>
            <HeaderInfo className="ml-2">Servis: <Strong600>{article.service}</Strong600></HeaderInfo>
        </div>
    }
}

class Clippings extends React.PureComponent {

    render() {
        const {client, clipping} = this.props;

        return client.clipping && <Flex className="p-1">
            {0 !== clipping.length ? <Clipping src={clipping[0].url}/>
            : <Clipping src="//via.placeholder.com/50?text=x"/>}
        </Flex>
    }
}

Clippings = connect(
    (state, props) => {
        return {
            client: state.client.data
        }
    }
)(Clippings);

export default class extends React.PureComponent<ArticlesListItemProps> {

    render(): React.Node {
        const {article, title, selected, checkbox, onSelect} = this.props;

        return <ArticleListItem onClick={() => onSelect(article.id)} selected={selected}>
            {checkbox && <Checkbox>{checkbox}</Checkbox>}
            {0 !== article.clipping.length ? <Clippings clipping={article.clipping}/> : <Flex className="p-1"><Clipping src={`//static.mediabase.sk/files/clippings/clipping/images_200x150/${article.article_attachments?.[0]?.file}`}/></Flex>}
            <Flex flex direction="column" className="p-1 justify-content-between">
                <Flex className="justify-content-between">
                    <Title>{title}</Title>
                    <HeaderInfo className="text-nowrap">{moment(article.date).format('DD MMM YYYY')}</HeaderInfo>
                </Flex>
                <Header article={article}/>
                <Flex className="justify-content-between align-items-center">
                    <Keywords keywords={article.keywords} short={true}/>
                    <Ratings rating={article.rating} />
                </Flex>
            </Flex>
        </ArticleListItem>
    }
}