//@flow

import * as React from "react";

import { Tooltip, Icon } from '.';


export default class extends React.PureComponent {

    static defaultProps = {
        size: 40
    };

    render() {
        const { selectAll, unselectAll, invertSelection, size } = this.props;

        return <div>
            <Icon id="select-all-articles" type="select" alt="select all" onClick={selectAll} size={size}/>
            <Tooltip target="select-all-articles" color="black">Označiť všetky</Tooltip>
            <Icon id="unselect-all-articles" type="deselect" alt="unselect all" onClick={unselectAll} size={size}/>
            <Tooltip target="unselect-all-articles" color="black">Odznačiť všetky</Tooltip>
            <Icon id="invert-selection-articles" type="invert" alt="invert selection" onClick={invertSelection} size={size}/>
            <Tooltip target="invert-selection-articles" color="black">Invertovať výber</Tooltip>
        </div>
    }
}
