import {graphqlAuthorized} from './axios';

export const PROFILE_REQUEST = 'core/PROFILE_REQUEST';
export const PROFILE_RECEIVE = 'core/PROFILE_RECEIVE';
export const PROFILE_ERROR = 'core/PROFILE_ERROR';

export default () => {
    return (dispatch, getState) => {
        dispatch({
            type: PROFILE_REQUEST
        });
        return graphqlAuthorized(getState()).post('/', {
            query: '{\n' +
                '  profile {\n' +
                '    id\n' +
                '    username\n' +
                '    email\n' +
                '    clients {\n' +
                '      id\n' +
                '      name\n' +
                '    }\n' +
                '  } \n' +
                '}\n'
        })
            .then(response => {
                return dispatch({
                    type: PROFILE_RECEIVE,
                    payload: response.data.data.profile
                });
            })
            .catch(error => {
                dispatch({
                    type: PROFILE_ERROR,
                    payload: error
                });
                throw error;
            })
        ;
    }
}
