import * as React from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';

import ArrayField from '../../../../fields/ArrayField';
import { categories } from '../../../../actions';
import { Checkbox, Flex, SelectUnselectInvert } from '../../../components';
import Filter from './Filter';

class Categories extends React.PureComponent {

    componentWillMount() {
        const { getCategories } = this.props;

        getCategories();
    }

    get label() {
        const { categories, input } = this.props;

        return <span>Kategórie
            {0 !== input.value.length && <span className="text-cloudy-blue font-weight-bold mx-2">
                <FontAwesome name="circle" className="fas"/>    {input.value.length}/{categories.items.length}
            </span>}
        </span>;
    }

    get active() {
        const { input } = this.props;

        return 0 !== input.value.length;
    }

    handleSelectAll = () => {
        const { categories: { items }, input: { onChange }} = this.props;

        onChange(items.map(item => item.id));
    };

    handleUnselectAll = () => {
        const {input: {onChange}} = this.props;

        onChange([]);
    };

    handleInvertSelection = () => {
        const { categories: { items }, input: { onChange, value }} = this.props;

        onChange(items.filter(item => -1 === value.indexOf(item.id)).map(item => item.id));
    };

    get header() {
        return <Flex flex className="justify-content-end">
            <SelectUnselectInvert size="20" selectAll={this.handleSelectAll} unselectAll={this.handleUnselectAll} invertSelection={this.handleInvertSelection}/>
        </Flex>
    }
    render() {
        const { categories, input, onClose } = this.props;

        return <Filter name="filter-categories" label={this.label} onClose={onClose} active={this.active} header={this.header} onClear={this.handleUnselectAll}>
            {categories.items.map(category => <ArrayField key={category.id} input={input} label={category.name} option={category.id} component={Checkbox}>{category.name}</ArrayField>)}
        </Filter>
    }
}

export default connect(
    (state, props) => {
        return {
            client: state.client,
            categories: state.categories
        }
    },
    (dispatch, props) => {
        return {
            getCategories: client => dispatch(categories(client.data.id))
        }
    },
    (stateProps, dispatchProps, ownProps) => {
        return Object.assign({}, ownProps, stateProps, dispatchProps, {
            getCategories: () => dispatchProps.getCategories(stateProps.client)
        });
    }
)(Categories);
