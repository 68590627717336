//@flow
import * as React from 'react';

import Histogram from './Histogram';
import withFilterForm from './withFilterForm';

export default ({name, query, initialValues}) => {

    return WrappedComponent => {

        class Connect extends React.PureComponent {

            render() {
                const {handleSubmit, ...rest} = this.props;

                return <Histogram onSubmit={handleSubmit} name={name} initialValues={initialValues} query={query}>
                    <WrappedComponent {...rest}/>
                </Histogram>
            }
        }

        return withFilterForm({
            form: name,
            initialValues: initialValues,
            query: query
        })(Connect);

    }
};

