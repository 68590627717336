import * as React from 'react';
import { Select2Field } from '../components';

export default class extends React.PureComponent {

    render() {
        return <Select2Field {...this.props} options={[
            {value: 0, label: 'denný'},
            {value: 1, label: 'týždenný'},
            {value: 2, label: 'dvojtýždenný'},
            {value: 3, label: 'mesačný'},
            {value: 4, label: 'dvojmesačný'},
            {value: 5, label: 'Štvrťročne'},
        ]}/>
    }
}
