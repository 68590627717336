import * as React from "react";
import 'react-vis/dist/style.css';
import moment from 'moment';

import {apricot, aquamarineBlue, dustyGray, seagull} from "../../variables";
import connect from './connect';
import RadialGraph from './RadialGraph';
import {ratingSummary} from "./queries";

class RatingSummaryGraph extends React.PureComponent {

    get legend() {
        return [
            {title: 'Nehodnotené', color: dustyGray},
            {title: 'Negatívne', color: apricot},
            {title: 'Neutrálne', color: seagull},
            {title: 'Pozitívne', color: aquamarineBlue}
        ];
    };

    get series() {
        const { histogram: { data }} = this.props;

        let result = [
            {name: 'Nehodnotené', color: dustyGray},
            {name: 'Negatívne', color: apricot},
            {name: 'Neutrálne', color: seagull},
            {name: 'Pozitívne', color: aquamarineBlue}
        ];
        data.forEach(item => {
            result[item.keys[0]]['y'] = item.count;
        });

        return result
    }

    render() {
        return <RadialGraph series={this.series} legend={this.legend} title="Polarita článkov"/>
    }
}

export default connect({
    name: 'ratingSummary',
    query: (name, client, from, to) => ratingSummary(name, {
        client: client,
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD')
    }),
    initialValues: {
        date: {
            from: moment().subtract(1, 'M').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD')
        }
    }
})(RatingSummaryGraph);
