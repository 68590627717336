import { combineReducers } from 'redux';

import { SOURCES_REQUEST, SOURCES_RECEIVE, SOURCES_ERROR } from '../actions/sources';

export default (state = {}, action) => {

    const isFetching = (state = false, action) => {
        switch (action.type) {
            case SOURCES_REQUEST:
                return true;
            case SOURCES_RECEIVE:
            case SOURCES_ERROR:
                return false;
            default:
                return state;
        }
    };

    const valid = (state = false, action) => {
        switch (action.type) {
            case SOURCES_RECEIVE:
                return true;
            default:
                return state;
        }
    };

    const items = (state = [], action) => {
        switch (action.type) {
            case SOURCES_RECEIVE:
                return action.payload;
            default:
                return state;
        }
    };

    return combineReducers({
        isFetching,
        valid,
        items
    })(state, action);
}