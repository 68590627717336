import * as React from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form'
import { withRouter } from 'react-router';
import styled from 'styled-components';
import moment from 'moment';

import { login } from '../actions';
import { Logo } from '../components';
import { Flex } from '../v2/components';

const ImageWraper = styled(Flex)`
    overflow: hidden;
`;

const LoginImage = styled.img`
    height: 100%;
`;

class Login extends React.PureComponent {

    render() {
        const { handleSubmit, error } = this.props;

        return <Flex flex className="flex-container">
            <ImageWraper flex="3">
                <LoginImage src={require('../assets/img/login.jpg')}/>
            </ImageWraper>
            <Flex flex="2">
                <Flex flex direction="column" className="align-items-center">
                    <div className="p-6 mt-6">
                        <Logo />
                    </div>
                    <Flex flex className="p-6">
                        <form>
                            <div className="form-group">
                                <Field name="email" component="input" type="text" className="form-control" placeholder="Prihlasovacie meno"/>
                            </div>
                            <div className="form-group">
                                <Field name="password" component="input" type="password" className="form-control" placeholder="Heslo"/>
                            </div>
                            {error && <div className="text-red">{error}</div>}
                            <input className="btn btn-cloudy-blue float-right mt-4" type="submit" value="Prihlásiť" onClick={handleSubmit}/>
                        </form>
                    </Flex>
                    <Flex className="p-3">
                        <div className="p-2 gray-500">&copy; 2018 - {moment().format('YYYY')} sixmedia s. r. o., SITA Slovenská tlačová agentúra a.s.</div>
                        <div className="ml-auto p-2 motto">Budťe <span className="invert">In</span>formovaný</div>
                    </Flex>
                </Flex>

            </Flex>
        </Flex>
    }
}

const LoginForm = reduxForm({
    form: 'login',
    onSubmit: (values, dispatch, props) => {
        return dispatch(login({
            login: values
        }))
            .then(() => {
                props.history.push('/monitoring')
            })
            .catch(() => {
                throw new SubmissionError({
                    password: 'Wrong password',
                    _error: 'Nesprávne prihlasovacie údaje'
                })
            });
    }
})(Login);

export default withRouter(LoginForm);