import ApolloClient from 'apollo-boost'
import {GRAPHQL_URL} from "../config";

export const GRAPHQL_REQUEST = 'core/GRAPHQL_REQUEST';
export const GRAPHQL_RECEIVE = 'core/GRAPHQL_RECEIVE';
export const GRAPHQL_ERROR = 'core/GRAPHQL_ERROR';

export default (name, root, query, variables) => {
    return (dispatch, getState) => {
        dispatch({
            type: GRAPHQL_REQUEST,
            payload: {
                name: name
            }
        });

        const client = new ApolloClient({
            uri: GRAPHQL_URL
        });

        return client.query({
            query: query,
            variables: variables,
            context: {
                headers: {
                    Authorization: 'Bearer ' + getState().login.data
                }
            },
            errorPolicy: 'all'
        })
            .then(response => {
                return dispatch({
                    type: GRAPHQL_RECEIVE,
                    payload: {
                        name: name,
                        data: response.data[root]
                    }
                });
            })
            .catch(error => {
                dispatch({
                    type: GRAPHQL_ERROR,
                    payload: {
                        name: name,
                        error: error
                    }
                });
                throw error;
            })
        ;
    }
}
