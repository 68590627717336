import * as React from 'react';
import styled from 'styled-components';

export default styled(({as = 'div', ...rest}) => {
    const Tag = as;

    return <Tag {...rest}/>
})`
    display: flex;
    min-height: 0;
    flex-direction: ${props => props.direction || "row"};
    flex: ${props => props.flex ? Number(props.flex) : "0 1 auto"}
`
