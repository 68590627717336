//@flow

import * as React from 'react';
import styled from 'styled-components';

const Image = styled.img.attrs({className: 'rounded cursor-pointer', alt: ''})`
    width: ${props => props.size ? props.size + 'px' : '50px'};
    height: ${props => props.size ? props.size + 'px' : '50px'};
    object-fit: cover;
    object-position: 0 0;
    ${props => props.selected && 'border: 1px solid red;'}
`;

const Clipping = ({...props}) => {
    if (-1 !== props.src.indexOf('mp4')) {
        return <a href={props.src} target="_blank">
            <Image {...props} src={require('../../assets/video.png')} onClick={null}/>
        </a>
    } else if (-1 !== props.src.indexOf('mp3')) {
        return <a href={props.src} target="_blank">
            <Image {...props} src={require('../../assets/audio.png')} onClick={null}/>
        </a>
    } else {
        return (
            <Image {...props} />
        )
    }
}


export default Clipping;