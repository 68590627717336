//@flow

import * as React from 'react';
import {Select2Field} from '../../../../components';

export default class extends React.PureComponent {

    get styles() {
        return {
            control: (provided, state) => ({
                ...provided,
                border: 0,
                'min-height': 0,
                background: 'transparent'
            // }),
            // input: () => ({
                // display: 'none'
            })
        }
    }

    get options() {
        return [
            {value: '_score', label: 'Podľa relevancie'},
            {value: 'monitoringDate', label: 'Podľa dátumu'},
            // {value: 's.name', label: 'Podľa zdroja'}
        ]
    }

    render() {
        return <Select2Field options={this.options} styles={this.styles} {...this.props} />
    }
}