import * as React from 'react';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';

import Input from './Input';

const InputIcon = styled(FontAwesome)`
    position: absolute;
    top: 10px;
    left: 14px;
`;

const InputWrapper = styled.div`
    position: relative;
`;

export default class extends React.PureComponent {

    handleChange = e => {
        const {onFilter} = this.props;

        if (13 === e.charCode) {
            onFilter();
        }
    };

    render() {
        const {input: {onChange, value}} = this.props;

        return <InputWrapper className="w-100">
            <InputIcon className="fal" name="search" size="lg"/>
            <Input placeholder="Hľadať" onChange={onChange} value={value} className="w-50 py-1 pl-5"
                   onKeyPress={this.handleChange}/>
        </InputWrapper>
    }
}
