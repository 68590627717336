import {anonymous} from './axios';

export const LOGIN_REQUEST = 'core/LOGIN_REQUEST';
export const LOGIN_RECEIVE = 'core/LOGIN_RECEIVE';
export const LOGIN_ERROR = 'core/LOGIN_ERROR';

export default data => {
    return dispatch => {
        dispatch({
            type: LOGIN_REQUEST
        });
        return anonymous.post('/login', data)
            .then(response => {
                dispatch({
                    type: LOGIN_RECEIVE,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: LOGIN_ERROR,
                    payload: error
                });
                throw error;
            })
        ;
    }
}
