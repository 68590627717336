import {graphqlAnonymous} from './axios';

export const GRAPHQL_REQUEST = 'core/GRAPHQL_REQUEST';
export const GRAPHQL_RECEIVE = 'core/GRAPHQL_RECEIVE';
export const GRAPHQL_ERROR = 'core/GRAPHQL_ERROR';

export default (name, root, query, variables) => {
    return (dispatch) => {
        dispatch({
            type: GRAPHQL_REQUEST,
            payload: {
                name: name
            }
        });

        return graphqlAnonymous.query({
            query: query,
            variables: variables,
            errorPolicy: 'all'
        })
            .then(response => {
                console.log(response);
                return dispatch({
                    type: GRAPHQL_RECEIVE,
                    payload: {
                        name: name,
                        data: response.data[root]
                    }
                });
            })
            .catch(error => {
                dispatch({
                    type: GRAPHQL_ERROR,
                    payload: {
                        name: name,
                        error: error
                    }
                });
                throw error;
            })
        ;
    }
}
