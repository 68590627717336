import * as React from 'react';

import Filter from './Filter';
import Ratings from '../Ratings';
import {Flex, SelectUnselectInvert} from "../../../components";

export default class extends React.PureComponent {

    handleSelectAll = () => {
        const {input: {onChange}} = this.props;

        return onChange([0, 1, 2, 3]);
    };

    handleUnselectAll = () => {
        const {input: {onChange}} = this.props;

        onChange([]);
    };

    handleInvertSelection = () => {
        const {input: {onChange, value}} = this.props;

        onChange([0, 1, 2, 3].filter(item => -1 === value.indexOf(item)));
    };

    get header(): React.Node {
        return <Flex flex className="justify-content-end">
            <SelectUnselectInvert size="20" selectAll={this.handleSelectAll} unselectAll={this.handleUnselectAll} invertSelection={this.handleInvertSelection}/>
        </Flex>
    }

    get active(): boolean {
        const { input: {value}} = this.props;

        return value.length;
    }

    render() {
        const { input, onClose } = this.props;

        return <Filter name="filter-rating" label="Hodnotenie" onClose={onClose} header={this.header} active={this.active} onClear={this.handleUnselectAll}>
            <Ratings input={input}/>
        </Filter>

    }
}
