import * as React from "react";
import { connect } from 'react-redux';

import { profile } from "../../actions";

class MainLayout extends React.PureComponent {

    componentDidMount() {
        const { getProfile } = this.props;

        getProfile();
    }

    render(): React.Node {
        return null
    }
}

export default connect(
    (state, props) => {
        return {
            client: state.client
        }
    },
    (dispatch, props) => {
        return {
            getProfile: () => dispatch(profile())
        }
    }
)(MainLayout);