import { combineReducers } from 'redux';

import { CHANGE_CLIENT } from '../actions/change-client';

export default (state = {}, action) => {

    const data = (state = null, action) => {
        switch (action.type) {
            case CHANGE_CLIENT:
                return action.payload;
            default:
                return state;
        }
    };

    return combineReducers({
        data
    })(state, action);
}