//@flow

import styled from 'styled-components';

import {Rating} from '.';
import {apricot, shark} from "../variables";

export default styled(Rating)`
    background-color: ${apricot}
    
    &:before {
        content: "";
        width: 7px;
        height: 7px;
        border-bottom: 1px solid ${shark};
        border-right: 1px solid ${shark};
        position: absolute;
        top: 5px;
        left: 7px;
        transform: rotate(45deg);
        border-radius: 1px;
    }
`;
