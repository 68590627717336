//@flow

import * as React from "react";
import { connect } from "react-redux";
import styled from 'styled-components';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import moment from "moment";
import {saveAs} from 'file-saver';

import { Flex, RatingPositive, RatingNeutral, RatingNegative, RatingUnrated, Img, Pill, Scrollable } from '..';
import Clipping from '../Clipping';
import Header from './Header';
import type { ClippingType, ArticleType } from "./types";
import Keywords from "../Keywords";
import Tooltip from "../Tooltip";
import { patchArticleRate, article } from '../../../actions';
import Loader from "../Loader";
import {API_URL} from "../../../config";

const Title = styled.h1`
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
`;

const Subtitle = styled.h2`
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    margin-bottom: 24px;
`;

const StyledAnnotation = styled.div`
    font-style: italic;
    margin-bottom: 20px;
`;

type ClippingsProps = {
    clippings: [ClippingType]
}

class Clippings extends React.Component<ClippingsProps> {

    state = {
        open: false,
        url: null
    };

    handleToggle = () => {
        this.setState({open: !this.state.open});
    };

    handleChange = url => {
        this.setState({url: url});
    };

    render() {
        const { article, client } = this.props;

        return client.clipping && <div>
            {article.clipping.length
                ? <Clipping src={article.clipping[0].url} className="mx-3 mb-3" onClick={this.handleToggle}/>
                : (article.article_attachments.length ? <Clipping src={`//static.mediabase.sk/files/clippings/clipping/images_200x150/${article.article_attachments[0]?.file}`} onClick={this.handleToggle}/>
                : <Clipping key="placeholder" src="https://via.placeholder.com/40" className="mx-3 mb-3"/>)}

            <Modal isOpen={this.state.open} toggle={this.handleToggle} size="lg">
                <ModalHeader toggle={this.handleToggle}>{article.title}</ModalHeader>
                <ModalBody>
                    <Flex>
                        <Flex direction="column">
                            {article.clipping.map(clipping => <Clipping key={clipping.id} selected={this.state.url === clipping.url} src={clipping.url} className="mx-3 mb-3" onClick={() => this.handleChange(clipping.url)}/>)}
                            {article.article_attachments.map(clipping => <Clipping src={`//static.mediabase.sk/files/clippings/clipping/images_700x950/${clipping.file}`}
                                                                                   selected={this.state.url === clipping.url}
                                                                                   className="mx-3 mb-3"
                                                                                   onClick={() => this.handleChange(`//static.mediabase.sk/files/clippings/clipping/images_700x950/${clipping.file}`)}
                            />)}
                        </Flex>
                        <Scrollable style={{maxHeight: '400px'}}>
                            <div>
                                {this.state.url && <img style={{'max-width': '100%', 'object-fit': 'contain'}} src={this.state.url} alt=""/>}
                            </div>
                        </Scrollable>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    {this.state.url && <Pill as="a" color="white" backgroundColor="black" href={this.state.url} target="_blank">Stiahnuť</Pill>}
                    <Pill color="white" backgroundColor="black" onClick={this.handleToggle}>Zatvoriť</Pill>
                </ModalFooter>
            </Modal>
        </div>
    }
}

Clippings = connect(
    (state, props) => {
        return {
            client: state.client.data
        }
    }
)(Clippings);

type ArticleProps = {
    getArticle: (id: number) => void,
    id: number,
    article: {
        isFetching: boolean,
        data: ArticleType
    }
};

class Article extends React.PureComponent<ArticleProps> {

    componentWillMount() {
        const { getArticle, id } = this.props;

        if (id) {
            getArticle(id);
        }
    }

    componentWillUpdate(nextProps) {
        if (nextProps.id !== this.props.id) {
            const { getArticle, id } = nextProps;

            if (id) {
                getArticle(id);
            }
        }
    }

    get article() {
        const {article: {data}} = this.props;

        return data.title + (data.subtitle ? '<br /><br />' + data.subtitle : '') + '<br /><br />' + data.content;
    }

    handleQuickExport = () => {
        const {article: {data}} = this.props;

        saveAs(`${API_URL}/article/${data.id}/export`, 'article.html');
    };

    render(): React.Node {
        const { article: { isFetching, data }, patchArticleRate, id} = this.props;

        if (isFetching || !data || !id) {
            return <Loader size="lg"/>;
        }

        return <Flex flex direction="column" className="w-100 p-2">
            <Flex className="justify-content-between py-1">
                <div id="article-rating">
                    {3 === data.rating && <RatingPositive/>}
                    {2 === data.rating && <RatingNeutral/>}
                    {1 === data.rating && <RatingNegative/>}
                    {0 === data.rating && <RatingUnrated/>}
                </div>
                <Tooltip target="article-rating" placement="bottom" innerClassName="bg-white" autohide={false}>
                    <RatingUnrated active={0 === data.rating} onClick={() => patchArticleRate(0)}/>
                    <RatingPositive active={3 === data.rating} onClick={() => patchArticleRate(3)}/>
                    <RatingNeutral active={2 === data.rating} onClick={() => patchArticleRate(2)} />
                    <RatingNegative active={1 === data.rating} onClick={() => patchArticleRate(1)}/>
                </Tooltip>
                <div>{moment(data.date).format('DD MMM YYYY - HH:mm')}</div>
                <Img className="cursor-pointer" id="article-share" type="share" style={{width: '25px', height: '25px'}}/>
                <Tooltip target="article-share" placement="bottom" innerClassName="bg-white" autohide={false} className="p-2">
                    <div className="text-black cursor-pointer p-1" onClick={this.handleQuickExport}>Stiahnuť článok</div>
                    <div className="text-black cursor-pointer p-1" onClick={() => window.print()}>Vytlačiť</div>
                </Tooltip>
            </Flex>
            <hr />
            <Flex>
                <Flex direction="column" flex>
                    <Header article={data}/>
                    <Keywords keywords={data.keywords} />
                </Flex>
                <Flex direction="column" className="align-items-center">
                    <Clippings article={data}/>
                </Flex>
            </Flex>
            <hr />
            <Flex flex direction="column">
                <Title dangerouslySetInnerHTML={{__html: data.title}}/>
                {data.subtitle && <Subtitle dangerouslySetInnerHTML={{__html: data.subtitle}}/>}
                {data.url && <div className="mb-2"><a href={data.url} style={{color: '#5468ff'}} target="_blank" rel="noopener noreferrer">{data.url}</a></div>}
                {data.annotation && <StyledAnnotation>{data.annotation}</StyledAnnotation>}
                <div id="article-content" dangerouslySetInnerHTML={{__html: data.content}}/>
            </Flex>
        </Flex>
    }
}

export default connect(
    (state, props) => {
        return {
            article: state.article,
            client: state.client
        }
    },
    (dispatch, props) => {
        return {
            getArticle: id => dispatch(article(id)),
            patchArticleRate: (clientId, articleId, rating) => {
                return dispatch(patchArticleRate(clientId, articleId, rating))
                    .then(() => dispatch(article(articleId)))
                    .catch(() => dispatch(article(articleId)))
            }
        }
    },
    (stateProps, dispatchProps, ownProps) => {
        return Object.assign({}, ownProps, stateProps, dispatchProps, {
            patchArticleRate: rating => dispatchProps.patchArticleRate(stateProps.client.data.id, stateProps.article.data.id, rating)
        });
    }
)(Article);