import * as React from 'react';
import {connect} from 'react-redux';
import {Field, clearFields, formValueSelector} from 'redux-form';

import Filter from './Filter';
import {Flex, SelectUnselectInvert} from "../../../components";
import SourceTypeSelect from "../../../../components/SourceTypeSelect";
import PeriodicitySelect from "../../../../components/PeriodicitySelect";
import ScopeSelect from "../../../../components/ScopeSelect";

class MoreFilters extends React.PureComponent {

    handleClear = async () => {
        const {clear} = this.props;

        await clear(['type', 'scope', 'periodicity', 'ave.from', 'ave.to']);
    };

    get header() {
        return <Flex flex className="justify-content-end">
            <SelectUnselectInvert size="20" selectAll={this.handleSelectAll} unselectAll={this.handleClear} invertSelection={this.handleInvertSelection}/>
        </Flex>
    }

    get active() {
        const { values } = this.props;

        return (undefined !== values.type) || (undefined !== values.periodicity) || (undefined !== values.scope) || (values.ave && (values.ave.from || values.ave.to))
    }

    render() {
        const {onClose } = this.props;

        return <Filter name="filter-ave" label="Ďalšie filtre" onClose={onClose} active={this.active} onClear={this.handleClear}>
            <div className="m-1">
                <Field name="type" component={SourceTypeSelect} placeholder="Vyberte typ"/>
            </div>
            <div className="m-1">
                <Field name="periodicity" component={PeriodicitySelect} placeholder="Vyberte periodicitu"/>
            </div>
            <div className="m-1">
                <Field name="scope" component={ScopeSelect} placeholder="Vyberte zameranie"/>
            </div>
            <div className="m-1">
                <label htmlFor="ave-from">AVE od:</label>
            </div>
            <div className="m-1">
                <Field id="ave-from" name="ave.from" component="input"/>
            </div>
            <div className="m-1">
                <label htmlFor="ave-to">AVE do:</label>
            </div>
            <div className="m-1">
                <Field id="ave-to" name="ave.to" component="input"/>
            </div>
        </Filter>

    }
}

export default connect(
    (state, props) => {
        return {
            values: formValueSelector('filter')(state, 'type', 'periodicity', 'scope', 'ave', 'ave.to')
        }
    },
    (dispatch, props) => {
        return {
            clear: (fields, value) => dispatch(clearFields('filter', false, false, ...fields))
        }
    }
)(MoreFilters)
