//@flow

import * as React from 'react';
import styled from 'styled-components';
import {formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import moment from "moment";

const ToolbarTitle = styled.h1`
    line-height: 49px;
`;

class Toolbar extends React.PureComponent {

    render() {
        const { date } = this.props;

        if ('string' === typeof date) {
            return <ToolbarTitle className="ml-3 mb-0">MONITORING ZO DŇA: {moment(date).format('DD. MM. YYYY')}</ToolbarTitle>
        }
        return <ToolbarTitle className="ml-3 mb-0">MONITORING ZO DŇA: {date.format('DD. MM. YYYY')}</ToolbarTitle>
    }

}

export default connect(
    (state, props) => {
        return {
            date: formValueSelector('monitoring')(state, 'date')
        }
    }
)(Toolbar);
