import { API_URL } from '../config';
import axios from 'axios';

export const ARTICLE_REQUEST = 'core/ARTICLE_REQUEST';
export const ARTICLE_RECEIVE = 'core/ARTICLE_RECEIVE';
export const ARTICLE_ERROR = 'core/ARTICLE_ERROR';

export default article => {
    return (dispatch, getState) => {
        dispatch({
            type: ARTICLE_REQUEST
        });
        return axios.get(API_URL + `/article/${article}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getState().login.data
            }
        })
            .then(response => {
                dispatch({
                    type: ARTICLE_RECEIVE,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: ARTICLE_ERROR,
                    payload: error
                });
                throw error;
            })
        ;
    }
}
