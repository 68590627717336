//@flow

import * as React from "react";
import {Field} from "redux-form";
import SearchInput from "./SearchInput";
import withFilterForm from "../pages/Export/withFilterForm";

export default class SearchField extends React.PureComponent {

    handleChange = () => {
        const {handleSubmit, onSubmit} = this.props;

        handleSubmit((values, dispatch, props) => onSubmit({
            button: 'filter',
            ...values
        }, dispatch, props))();
    };

    render() {
        return <Field name="query" component={SearchInput} onFilter={this.handleChange}/>
    }
}

export const FilterSearchField = withFilterForm(SearchField);