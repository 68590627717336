import * as React from "react";
import 'react-vis/dist/style.css';
import moment from 'moment';

import connect from './connect';
import Graph from './BarSeriesGraph';
import {authorSummary} from "./queries";

class AuthorsSummaryGraph extends React.PureComponent {

    get results() {
        const { histogram: { data }} = this.props;
        let result = [];

        data.forEach(item => {
            result = {
                ...result,
                [item.keys[0]] : item.count
            };
        });

        return result;
    }

    get legend() {
        return Object.keys(this.results);
    }

    get series() {
        return [{
            name: 'Autori', data: Object.values(this.results)
        }]
    }

    render() {
        return <Graph legend={this.legend} series={this.series} title="Počet článkov podľa autora - TOP 10"/>
    }
}

export default connect({
    name: 'authors',
    query: (name, client, from, to) => authorSummary(name, {
        client: client,
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD')
    }),
    initialValues: {
        date: {
            from: moment().subtract(1, 'M').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD')
        }
    }
})(AuthorsSummaryGraph);
