import gql from 'graphql-tag';

import {graphql} from "../../../../actions";

export default (name, variables) => graphql(name, 'analysis', gql`query Analysis(
    $client: Int!
    $from: String!
    $to: String!
) {
    analysis(
        client: $client,
        from: $from,
        to: $to,
        field: "COUNT(a.id)",
        buckets: [
            {
                bucket: "(SELECT name AS id FROM categories WHERE client_id = :client_id)", 
                field: "c.name"
            },
            {
                bucket: "(SELECT DISTINCT rating AS id FROM articles)", 
                field: "a.rating"
            }
        ]
    ) {
        keys
        count
    }
}`, variables)
