//@flow

import * as React from "react";

import { MainLayout } from '.';
import { Sidebar, Content } from '../components';

type DoubleColumnLayoutProps = {
    sidebar: React.Node,
    children: React.Node
}

export default class extends React.PureComponent<DoubleColumnLayoutProps> {

    render(): React.Node {
        const { sidebar, children, ...props } = this.props;

        return <MainLayout {...props}>
            <Sidebar id="sidebar">{sidebar}</Sidebar>
            <Content flex>{children}</Content>
        </MainLayout>
    }
}
