//@flow

import * as React from 'react';
import styled from 'styled-components';

const Icon = styled.img`
    width: ${props => props.size}px;
`;

export type MenuItemType = 'monitoring' | 'export' | 'archive' | 'analytics' | 'clients';

type IconType = {
    id: string,
    type: MenuItemType,
    size: number
}

export default class extends React.PureComponent<IconType> {

    static defaultProps = {
        size: 40
    };

    render(): React.Node {
        const {type, ...rest} = this.props;

        return <Icon src={require(`../../assets/img/${type}.svg`)} alt={type} {...rest}/>
    }
}