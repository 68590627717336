//@flow

import * as React from 'react';
import classnames from 'classnames';
import { Label } from 'reactstrap';
import styled from 'styled-components';

const CheckboxWrapper = styled.div`
    overflow: hidden; 
    position: relative;
`;

const Checkbox = styled.div.attrs(props => ({className: classnames({checked: props.checked})}))`
    width: ${props => 'sm' === props.size ? '16px' : '20px'};
    height: ${props => 'sm' === props.size ? '16px' : '20px'}; 
    display: inline-block; 
    border: 1px solid black; 
    position: relative;
    border-radius: 7px; 
    background-color: white; 
    box-shadow: 0 0 1px 0 gray; 
    border-color: black;
    
    &.checked:after { 
        content: ""; 
        width: 14px; 
        height: 14px; 
        border-radius: 5px;
        background-color: black; 
        position: absolute; 
        top: 2px; 
        left: 2px;
    }
    
    label { line-height: 20px; }
    
    .checkbox-tick { 
        border-width: 0 1px 1px 0; 
        border-style: solid; 
        border-color: white; 
        display: inline-block;
        width: 4px; 
        height: 6px; 
        position: absolute; 
        top: 3px; 
        left: 5px; 
        transform: rotate(45deg);
    }

`;

type CheckboxProps = {
    checked: boolean,
    children: string,
    onChange: () => void
};

export default class extends React.PureComponent<CheckboxProps> {

    props: CheckboxProps;

    render(): React.Node {
        const { checked, onChange, children} = this.props;

        return <CheckboxWrapper className="text-nowrap cursor-pointer" onClick={onChange}>
            <Checkbox checked={checked} />
            {children && <Label className="m-0 ml-2 align-top">{children}</Label>}
        </CheckboxWrapper>
    }
}