import * as React from 'react';
import { Select2Field } from '../components';

export default class extends React.PureComponent {

    render() {
        return <Select2Field {...this.props} options={[
            { value: 0, label: 'agentúra'},
            { value: 1, label: 'online'},
            { value: 2, label: 'print'},
            { value: 3, label: 'rádio'},
            { value: 4, label: 'TV'}
        ]}/>
    }
}
