//@flow

import * as React from 'react';
import styled from "styled-components";
import {apricot} from "../variables";

const Keywords = styled.div`
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
        color: ${apricot};
        font-weight: bold;
        text-transform: capitalize;
    }
`;

type KeywordsProps = {
    keywords: string,
    short: boolean
}

export default class extends React.PureComponent<KeywordsProps> {

    get keywords() {
        const { keywords, short = false } = this.props;

        if (short) {
            const items = keywords.split(', ');
            7 < items.length ? items.splice(7, items.length, '...') : items.splice(7, items.length);
            return items.join(', ');
        }
        return keywords;
    }

    render() {
        return <Keywords>
            <strong>Kľúčové slová:</strong> <span>{this.keywords}</span>
        </Keywords>
    }
}
