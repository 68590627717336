import * as React from "react";
import moment from "moment";
import FontAwesome from 'react-fontawesome';

import { DatePicker } from '../../../../components';
import { Checkbox } from '../../../components';
import Filter from './Filter';

class DateType extends React.PureComponent {

    render() {
        const { value, onChange } = this.props;

        return <div className="d-flex justify-content-between m-3">
            <Checkbox checked={'monitoring' === value} onChange={() => onChange('monitoring')}>Dátum monitoringu</Checkbox>
            <Checkbox checked={'publish' === value} onChange={() => onChange('publish')}>Dátum publikovania</Checkbox>
        </div>
    }
}

export default class extends React.PureComponent {

    handleChange = date => {
        const { input: { value, onChange }} = this.props;

        onChange({
            ...value,
            from: date.from ? date.from.format('YYYY-MM-DD') : null,
            to: date.to ? date.to.format('YYYY-MM-DD') : null
        });
    };

    handleChangeType = type => {
        const { input: { value, onChange }} = this.props;

        onChange({
            ...value,
            type: type
        });
    };

    get label() {
        const { input: { value }} = this.props;

        return <span><FontAwesome name="calendar" className="fal"/><span className="ml-2">{moment(value.from).format('DD. MMM YYYY')} - {moment(value.to).format('DD. MMM YYYY')}</span></span>
    }

    get active() {
        return true;
    }

    render() {
        const { input: { value }, onClose} = this.props;

        return <Filter name="filter-date" label={this.label} onClose={onClose} active={this.active}>
            <DatePicker range={true} from={value.from ? moment(value.from) : null} to={value.to ? moment(value.to) : null} onChange={this.handleChange}/>

            <DateType value={value.type} onChange={this.handleChangeType}/>
        </Filter>
    }
}
