// http://chir.ag/projects/name-that-color/
import alpha from 'color-alpha';

const shark = '#181a22';
const wildSand = '#f4f4f4';
const gray = '#878484';
const dustyGray = '#979797';
const dustyGray25 = alpha(dustyGray, .25);
const gallery = '#ededed';
const gallery09 = alpha(gallery, .09);
const gallery25 = alpha(gallery, .25);
const gallery54 = alpha(gallery, .54);
const apricot = '#ea7a7a';
const seagull = '#79ceea';
const seagull25 = alpha(seagull, .25);
const aquamarineBlue = '#63e1b4';
const stormyGray = '#6d7284';
const ebonyClay = '#262a3a';
const lightishBlue = '#5468ff';

type colors = shark
    | wildSand
    | gray
    | dustyGray | dustyGray25
    | gallery | gallery09 | gallery25 | gallery54
    | apricot
    | seagull | seagull25
    | stormyGray
    | ebonyClay
    | lightishBlue

export {
    shark,
    wildSand,
    gray,
    dustyGray,
    dustyGray25,
    gallery,
    gallery09,
    gallery25,
    gallery54,
    apricot,
    seagull,
    seagull25,
    aquamarineBlue,
    stormyGray,
    ebonyClay,
    lightishBlue
}

export type {
    colors
};