import { API_URL } from '../config';
import axios from 'axios';

export const EXPORTS_REQUEST = 'core/EXPORTS_REQUEST';
export const EXPORTS_RECEIVE = 'core/EXPORTS_RECEIVE';
export const EXPORTS_ERROR = 'core/EXPORTS_ERROR';

export default (client, type) => {
    return (dispatch, getState) => {
        dispatch({
            type: EXPORTS_REQUEST
        });
        return axios.get(API_URL + `/client/${client}/exports/${type}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getState().login.data
            }
        })
            .then(response => {
                return dispatch({
                    type: EXPORTS_RECEIVE,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: EXPORTS_ERROR,
                    payload: error
                });
                throw error;
            })
        ;
    }
}
