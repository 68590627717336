import * as React from 'react';
import moment from 'moment';

import { Pill, Flex } from '../../v2/components';
import {ebonyClay} from "../../v2/variables";

class FastButton extends React.PureComponent {

    render() {
        const { children, onClick } = this.props;

        return <Pill color="white" backgroundColor={ebonyClay} className="px-2 py-1" onClick={onClick}>{children}</Pill>
    }
}

export default class extends React.PureComponent {

    handleChange = (from, to) => {
        const { onChange } = this.props;

        onChange(from, to);
    };

    render() {

        return <Flex direction="column">
            <Flex flex className="justify-content-between m-2">
                <FastButton onClick={() => this.handleChange(moment(), moment())}>Dnes</FastButton>
                <FastButton onClick={() => this.handleChange(moment().subtract(1, 'd'), moment().subtract(1, 'd'))}>Včera</FastButton>
                <FastButton onClick={() => this.handleChange(moment().subtract(1, 'w').add(1, 'd'), moment())}>Posledný týždeň</FastButton>
            </Flex>
            <Flex flex className="justify-content-between m-2">
                <FastButton onClick={() => this.handleChange(moment().subtract(1, 'M').add(1, 'd'), moment())}>Posledný mesiac</FastButton>
                <FastButton onClick={() => this.handleChange(moment().subtract(1, 'y').startOf('year'), moment().subtract(1, 'y').endOf('year'))}>Posledný rok</FastButton>
                <FastButton onClick={() => this.handleChange(moment().startOf('y'), moment())}>Tento rok</FastButton>
            </Flex>
        </Flex>;
    }

}