import axios from 'axios';
import ApolloClient from 'apollo-boost';

import {API_URL, GRAPHQL_URL} from '../config';

export const authorized = (state) => axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + state.login.data
    }
});

export const anonymous = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

export const graphqlAuthorized = (state) => axios.create({
    baseURL: GRAPHQL_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + state.login.data
    }
});

export const graphqlAnonymous = new ApolloClient({
    uri: GRAPHQL_URL
});
