import {connect} from 'react-redux';
import {reduxForm, SubmissionError} from "redux-form";
import moment from "moment";
import {withRouter} from 'react-router';

import postExport from "../../../actions/post-export";
import {filter} from "../../../queries";

export default WrappedComponent => {

    WrappedComponent = reduxForm({
        form: 'filter',
        initialValues: {
            date: {
                from: moment().format('YYYY-MM-DD'),
                to: moment().format('YYYY-MM-DD'),
                type: 'monitoring'
            },
            articles: [],
            sources: [],
            categories: [],
            orderBy: '_score',
            direction: 'desc'
        },
        onSubmit: (values, dispatch, props) => {
            const { button, ...data } = values;

            if ('filter' === button) {
                return dispatch(filter({
                    client: props.client.data.id,
                    query: data.query ? data.query : null,
                    from: data.date.from,
                    to: data.date.to,
                    dateType: data.date.type,
                    categories: data.categories,
                    sources: data.sources,
                    ratings: data.ratings || null,
                    periodicity: undefined === data.periodicity ? null : data.periodicity,
                    type: undefined === data.type ? null : data.type,
                    scope: undefined === data.scope ? null : data.scope,
                    aveFrom: data.ave?.from || null,
                    aveTo: data.ave?.to || null,
                    orderBy: data.orderBy,
                    direction: data.direction.toUpperCase()
                }));
            } else if ('export' === button) {
                // @todo refactor to proper validation
                if (!data.export_setting) {
                    throw new SubmissionError({
                        export_setting: 'required'
                    });
                }

                return dispatch(postExport(props.client.data.id, {
                    'export': {
                        query: data.query,
                        startAt: data.date.from,
                        endAt: data.date.to,
                        dateType: data.date.type,
                        categories: data.categories ? data.categories : [],
                        sources: data.sources ? data.sources : [],
                        ratings: data.ratings,
                        periodicity: data.periodicity,
                        type: data.type,
                        scope: data.scope,
                        aveFrom: data.ave ? data.ave.from : undefined,
                        aveTo: data.ave ? data.ave.to : undefined,
                        articles: data.articles ? data.articles : [],
                        exportSetting: data.export_setting,
                    },
                    orderBy: data.orderBy,
                    direction: data.direction
                })).then(() => props.history.push('/archive/custom'))
            }
        }
    })(WrappedComponent);

    WrappedComponent = connect(
        (state, props) => {
            return {
                client: state.client
            }
        }
    )(WrappedComponent);

    return withRouter(WrappedComponent);
}
