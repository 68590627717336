//@flow

import * as React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink} from 'reactstrap';
import {withRouter} from "react-router";
import styled from 'styled-components';

import {Flex} from '../../components';

const StyledNavLink = styled(NavLink)`
    cursor: pointer;
`;

class Help extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tab: 'login'
        }
    }

    handleToggle = () => {
        const { location, history } = this.props;

        history.push({
            pathname: location.pathname
        });
    };

    handleTab = tab => {
        this.setState({tab: tab});
    }

    render() {
        return <Modal isOpen={true} toggle={this.handleToggle} size="lg">
            <ModalHeader toggle={this.handleToggle}>Nápoveda</ModalHeader>
            <ModalBody>
                <Flex>
                    <Flex as={Nav} direction="column">
                        <NavItem><StyledNavLink onClick={() => this.handleTab('login')}>Základné informácie</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('start')}>Začíname pracovať</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('monitoring')}>Práca v záložke MONITORING</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('export')}>Práca v záložke EXPORT</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('filter')}>Používanie filtrov</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('calendar')}>Práca s kalendárom</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('filter-date')}>Ručné nastavenie dátumu</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('filter-category')}>Filter „Kategórie“</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('filter-sources')}>Práca so zdrojmi</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('articles')}>Práca s článkami</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('analysis')}>Práca v záložke ANALÝZA</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('clients')}>Práca v záložke KLIENTI</StyledNavLink></NavItem>
                    </Flex>
                    {'login' === this.state.tab && <Flex flex={true} direction="column">
                        <h2>Základné informácie o systéme Mediabase</h2>
                        <p>Monitorovací systém Mediabase umožňuje prezerať články a prepisy relácií vo viac ako 1 200 zdrojoch, ktoré splňujú zadané kritériá výberu. Jeho výhodou je okrem komplexnosti dát aj priložený printscreen webových portálov a sken príslušnej strany pri tlačených médiách. Umožňuje tak komfortný a ucelený prehľad o mediálnych výstupoch.</p>
                        <p><img src={require('./assets/login.png')} width="100%"/></p>
                    </Flex>}
                    {'start' === this.state.tab && <Flex flex={true} direction="column">
                        <h2>Začíname pracovať</h2>
                        <p>Systém Mediabase ponúka po prihlásení zobrazenie monitoringu médií za aktuálny deň.</p>
                        <p><img src={require('./assets/start-working.png')} width="100%"/></p>
                    </Flex>}
                    {'monitoring' === this.state.tab && <Flex flex={true} direction="column">
                        <h2>Práca v záložke MONITORING</h2>
                        <p>Systém Mediabase umožňuje filtrovať denný monitoring podľa „Kategórií“ a taktiež podľa dátumu monitoringu. Ak je pri dátume oranžová bodka, znamená to, že pre tento deň sú v systéme relevantné články. Aktuálne vybraný deň je zvýraznený tmavosivou plochou s bielou bodkou.</p>
                        <p><img src={require('./assets/monitoring.png')} width="100%"/></p>
                        <p>V rámci tejto záložky je možné ohodnotiť polaritu jednotlivých príspevkov. K dispozícii sú štyri stavy: nehodnotený (sivá -), pozitívny (zelená ˄), neutrálny (modrá -) a negatívny (červená ˅).</p>
                        <p><img src={require('./assets/monitoring2.png')} width="100%"/></p>
                        <p>a taktiež pracovať s klipmi, pokiaľ daný článok klip má.</p>
                        <p><img src={require('./assets/monitoring3.png')} width="100%"/></p>
                        <p>Po kliknutí na náhľad klipu sa zobrazí plný klip daného článku.</p>
                        <p><img src={require('./assets/monitoring4.png')} width="100%"/></p>
                        <p>Ak potrebujete vytlačiť otvorený článok, resp. zobraziť ho na samostatnej záložke, stačí stlačiť ikonku vpravo hore v detaile článku a vybrať si možnosť „Stiahnuť článok“ alebo „Vytlačiť“.</p>
                        <p><img src={require('./assets/monitoring5.png')} width="100%"/></p>
                        <p><img src={require('./assets/monitoring6.png')} width="100%"/></p>
                    </Flex>}
                    {'export' === this.state.tab && <Flex flex={true} direction="column">
                        <h2>Práca v záložke EXPORT</h2>
                        <p>Systém Mediabase umožňuje vyhľadávať v článkoch, ktoré sú v systéme. Stačí zadať vami hľadaný výraz do horného okna a stlačiť enter.</p>
                        <p><img src={require('./assets/export.png')} width="100%"/></p>
                    </Flex>}
                    {'filter' === this.state.tab && <Flex flex={true} direction="column">
                        <h2>Používanie filtrov</h2>
                        <p>Systém Mediabase umožňuje viacnásobné filtrovanie. Vyhľadávané články je možné filtrovať podľa kategórií, zdrojov, hodnotenia, typu periodika, periodicity, zamerania periodika resp. podľa hodnoty AVE.</p>
                        <p><img src={require('./assets/filter.png')} width="100%"/></p>
                    </Flex>}
                    {'calendar' === this.state.tab && <Flex flex={true} direction="column">
                        <h2>Práca s kalendárom</h2>
                        <p>Kalendár slúži na výber časového intervalu v ktorom chcete vyhľadávať zvolený reťazec.</p>
                        <p>Systém SearchIn je nastavený tak, že sa automaticky navolí posledný ukončený deň. Ak potrebujete zmeniť interval vyhľadávania, kliknite na tlačidlo s ikonou kalendár na obrazovke hore. </p>
                        <p><img src={require('./assets/calendar.png')} width="100%"/></p>
                        <p>Kalendár ponúka viacero prednastavených možností vyhľadávania ako napríklad: dnes, včera, posledných 7 dní atď. Ak kliknete napríklad na „Posledných 7 dní“ systém automaticky vysvieti posledných sedem dní v kalendári. Výber potvrdíte stlačením tlačidla „Potvrdiť“.</p>
                        <p><img src={require('./assets/calendar2.png')} width="100%"/></p>
                    </Flex>}
                    {'filter-date' === this.state.tab && <Flex flex={true} direction="column">
                        <h2>Ručné nastavenie dátumu</h2>
                        <p>Dátum vyhľadávania od určeného dňa a do určeného dňa môžete nastaviť aj ručne.</p>
                        <p>Myšou označíme dátum od a súčasne aj dátum do.</p>
                        <p><img src={require('./assets/filter-date.png')} width="100%"/></p>
                        <p>Po zvolení dátumového rozsahu výber potvrdíte stlačením tlačidla "Potvrdiť".</p>
                        <p>Systém Mediabase je tak pripravený na vyhľadávanie. Po stlačení tlačidla "Vyhľadať" zobrazí všetky články, ktoré spĺňajú vami zvolené kritériá.</p>
                    </Flex>}
                    {'filter-category' === this.state.tab && <Flex flex={true} direction="column">
                        <h2>Filter „Kategórie“</h2>
                        <p>Systém Mediabase umožňuje vybrať si konkrétnu kategóriu, resp. viacero kategórií, v ktorej chcete hľadať jednoduchým kliknutím na tlačidlo "Kategórie" a následným výberom zvolenej kategórie.</p>
                        <p><img src={require('./assets/filter-category.png')} width="100%"/></p>
                        <p>Pole kategórie po vybratí aspoň jednej kategórie zmení farbu, čím indikuje, že je filter aktívny a súčasne vypíše koľko kategórií z celkového počtu je aktivovaných</p>
                    </Flex>}
                    {'filter-sources' === this.state.tab && <Flex flex={true} direction="column">
                        <h2>Práca so zdrojmi</h2>
                        <p>Systém Mediabase umožňuje vybrať si konkrétny zdroj, resp. skupinu zdrojov, v ktorej chcete hľadať jednoduchým kliknutím na tlačidlo "Zdroje".</p>
                        <p><img src={require('./assets/filter-sources.png')} width="100%"/></p>
                        <p>K dispozícii je osem skupín zdrojov. Každá skupina sa dá samostatne zapnúť alebo vypnúť.</p>
                        <p>Ak hľadáte konkrétny zdroj, stačí zadať časť z názvu zdroja do vyhľadávacieho okna a systém Mediabase zobrazí všetky zdroje, ktoré zadané znaky obsahujú. V prípade, že si nezvolíte žiadny zdroj, systém Mediabase automaticky prehľadá všetky dostupné zdroje.</p>
                        <p><img src={require('./assets/filter-sources2.png')} width="100%"/></p>
                        <p>Aj tento filter po aktivácií zmení farbu a vypíše, koľko zdrojov je z celej databázy vybraných na zobrazenie.</p>
                    </Flex>}
                    {'articles' === this.state.tab && <Flex flex={true} direction="column">
                        <h2>Práca s článkami</h2>
                        <p>Systém Mediabase vyhľadá články podľa vami zvolených kritérií. Ich počet nájdete pod zadávacím oknom vľavo.</p>
                        <p>Zoznam hlavičiek článkov sa zobrazí v ľavom okne. Pravé okno slúži na zobrazenie detailu článku.</p>
                        <p><img src={require('./assets/articles.png')} width="100%"/></p>
                        <p>Ak kliknete na vybraný článok v zozname, zobrazí sa v pravom okne celý v médiách zverejnený text.</p>
                        <p>Vybrané články zaradíme do exportu zakliknutím políčka vľavo od hlavičky článku.</p>
                        <p><img src={require('./assets/articles2.png')} width="100%"/></p>
                        <p>Na exportovanie vybraných článkov slúži tlačidlo "Exportovať".</p>
                        <p><img src={require('./assets/articles3.png')} width="100%"/></p>
                        <p>Po jeho stlačení systém Mediabase umožní cez tlačidlá v pravom hornom rohu vybrať typ exportu (txt, html, doc...). </p>
                        <p><img src={require('./assets/articles4.png')} width="100%"/></p>
                        <p>Stlačením tlačidla "Export" systém vygeneruje požadovaný typ exportu a prepne sa do záložky „ARCHÍV“, kde si môžete vytvorený export otvoriť, resp. stiahnuť.</p>
                        <p><img src={require('./assets/articles5.png')} width="100%"/></p>
                    </Flex>}
                    {'analysis' === this.state.tab && <Flex flex={true} direction="column">
                        <h2>Práca v záložke ANALÝZA</h2>
                        <p>Systém Mediabase umožňuje zobraziť viacero analytických grafov. Na výber je „polarita článkov podľa kategórií“, „polarita článkov“, „hodnota AVE podľa kategórií“, „počet článkov podľa kategórií“, „počet článkov podľa zdroja“ a „počet článkov podľa autora“. Systém je nastavený tak, že automaticky zvolí dátumové rozpätie jedného mesiaca. </p>
                        <p><img src={require('./assets/analysis.png')} width="100%"/></p>
                        <p>Systém umožňuje stiahnuť graf vo viacerých formátoch, resp. zobraziť graf v režime full screen resp. vytlačiť graf. Stačí kliknúť vpravo hore na ikonku</p>
                        <p><img src={require('./assets/analysis2.png')} width="100%"/></p>
                        <p>Následne sa otvorí okno, ktoré umožňuje prácu s vygenerovaným grafom.</p>
                        <p><img src={require('./assets/analysis3.png')} width="100%"/></p>
                        <p>V prípade, že potrebujete zmeniť dátumové rozpätie, stačí opäť kliknúť na pole s dátumom a vybrať si buď z predvolených hodnôt, alebo zadať dátum ručne.</p>
                        <p><img src={require('./assets/analysis4.png')} width="100%"/></p>
                        <p>Ak chcete zmeniť graf, stačí kliknúť na názov grafu a systém vygeneruje nový graf automaticky.</p>
                        <p><img src={require('./assets/analysis5.png')} width="100%"/></p>
                    </Flex>}
                    {'clients' === this.state.tab && <Flex flex={true} direction="column">
                        <h2>Práca v záložke KLIENTI</h2>
                        <p>V prípade, že máte viac ako jeden monitoring, systém z záložke klienti zobrazí všetky aktívne profily.</p>
                        <p><img src={require('./assets/clients.png')} width="100%"/></p>
                        <p>Monitoring pre vybraný profil sa aktivuje kliknutím naň. Systém sa následne prepne do záložky „Monitoring“</p>
                    </Flex>}
                </Flex>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.handleToggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

export default withRouter(Help);