import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { ArticleListItem, CategoryTitle, Flex, Scrollable, Loader } from '../../../components'
import Pagination from './Pagination';


class ArticleItems extends React.PureComponent {

    componentDidMount() {
        const { monitoring, selected, onSelect} = this.props;

        let found = false;
        monitoring.items.map(article => {
            if (selected === article.id) {
                found = true;
            }
            return null;
        });

        !found && monitoring.items[0] && onSelect(monitoring.items[0].id);
    }

    componentDidUpdate() {
        const { monitoring, selected, onSelect} = this.props;

        let found = false;
        monitoring.items.map(article => {
            if (selected === article.id) {
                found = true;
            }
            return null;
        });

        !found && monitoring.items[0] && onSelect(monitoring.items[0].id);
    }

    render() {
        const { monitoring, articles, selected, onSelect} = this.props;

        if (monitoring.isFetching) {
            return <div className="w-100 p-2">
                <Loader />
            </div>
        }

        if (0 === monitoring.items.length) {
            return <div className="w-100 p-2">Nepodarilo sa najsť žiadne relevantné články</div>
        }

        return <div className="w-100 p-2">
            {Object.keys(articles).map((category, index) => {
                return <div key={index}>
                    <CategoryTitle className="p-3">{category}</CategoryTitle>
                    <div>{articles[category].map(article => <ArticleListItem key={article.id} article={article} title={article.title} onSelect={onSelect} selected={selected === article.id}/>)}</div>
                </div>
            })}
        </div>

    }
}

ArticleItems = connect(
    (state, props) => {
        const articles = [];
        state.monitoring.items.map(
            article => article.articles_tags.map(
                articleTag => articleTag.tag.categories_tags.map(
                    categoryTag => {
                        if (undefined === articles[categoryTag.category.name]) {
                            articles[categoryTag.category.name] = [];
                        }
                        articles[categoryTag.category.name].push(article);
                        return null;
                    }
                )
            )
        );
        return {
            monitoring: state.monitoring,
            articles: articles,
        }
    }
)(ArticleItems);

type ArticlesListProps = {
    articles: {}
}

class ArticlesList extends React.PureComponent<ArticlesListProps> {

    render(): React.Node {
        const { count, selected, onSelect} = this.props;

        return <Flex flex direction="column">
            <Flex flex>
                <Scrollable flex>
                    <ArticleItems onSelect={onSelect} selected={selected}/>
                </Scrollable>
            </Flex>
            <Field name="page" component={Pagination} count={count}/>
        </Flex>
    }
}

export default connect(
    (state, props) => {
        return {
            count: state.monitoring.meta.count
        }
    }
)(ArticlesList);
