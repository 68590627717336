//@flow

import * as React from 'react';
import styled from 'styled-components';

import { gallery, seagull } from "../variables";
import Flex from "./Flex";

const Page = styled.span`
    width: 34px;
    height: 34px;
    line-height: 34px;
    vertical-align: baseline;
    text-align: center;
    border-radius: 5px;
    background-color: ${props => props.active ? seagull : gallery}
    color: black;
`;

type PaginationProps = {
    onChange: (page: number) => void,
    count: number,
    page: number,
    limit: ?number
};

export default class extends React.PureComponent<PaginationProps> {

    static defaultProps = {
        page: 1,
        count: 1,
        limit: 30

    };

    handleSelect = (page: number) => {
        const { onChange } = this.props;

        onChange(page);
    };

    get last() {
        const { count, limit } = this.props;

        return Math.ceil(count/limit);
    }

    get pages() {
        const {page} = this.props;

        if (this.last < 7) {
            return this.last;
        }

        let pages = 5;
        if (page === 4) {
            pages++;
        }
        if (page === this.last - 3) {
            pages++;
        }

        return pages;
    }

    get offset() {
        return Math.floor(this.pages / 2);
    }

    get start() {
        const {page} = this.props;

        if (page + this.offset >= this.last) {
            return Math.max(1, this.last - (this.pages - 1));
        } else {
            return Math.max(1, page - this.offset);
        }
    }

    get end() {
        return Math.min(this.last, this.start + (this.pages - 1))
    }

    render(): React.Node {
        const { page } = this.props;


        const pages = [];

        for (let i = this.start; i <= this.end; i++) {
            pages[pages.length] = i;
        }

        return <Flex className="m-3 justify-content-center">
            {(this.start > 2) && <Page className="m-1 mr-4 cursor-pointer" onClick={() => this.handleSelect(1)} active={1 === page}>{1}</Page>}
            {pages.map(item => <Page key={item} className="m-1 cursor-pointer" onClick={() => this.handleSelect(item)} active={item === page}>{item}</Page>)}
            {(this.end < this.last - 1) && <Page className="m-1 ml-4 cursor-pointer" onClick={() => this.handleSelect(this.last)} active={this.last === page}>{this.last}</Page>}
        </Flex>
    }
}
