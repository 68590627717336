import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import styled from 'styled-components';

import DateNames from './DateNames';
import Navigation from './Navigation';
import Dates from './Dates';
import FastNavigation from './FastNavigation';
import { monitorings } from '../../actions';

moment.updateLocale('en', {
    week: {
        dow: 1
    }
});

const Picker = styled.div`
    // width: 343px;
`;

const ExpandArrow = styled.img.attrs({className: 'p-1'})`
    width: 150px;
    height: 40px;
    opacity: .5;
`;

type DatePickerProps = {
    active: ?string,
    from: ?any,
    to: ?any,
    range: any,
    expanded: boolean,
    onChange: () => void
};

class DatePicker extends React.Component<DatePickerProps> {

    static defaultProps = {
        expanded: true,
        active: moment()
    };

    constructor(props) {
        super(props);

        this.state = {
            active: props.active,
            from: props.from,
            to: props.to,
            hover: null,
            expanded: props.expanded
        };
    }

    componentDidMount() {
        const { getMonitorings } = this.props;

        getMonitorings(this.state.active.format('YYYY'), this.state.active.format('MM'));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { from, to, expanded } = this.props;

        if (prevProps.from !== from) {
            this.setState({ from: from});
        }
        if (prevProps.to !== to) {
            this.setState({ to: to});
        }
        if (prevProps.expanded !== expanded) {
            this.setState({ expanded: expanded });
        }
    }

    handleMoveMonth = count => {
        const { expanded, getMonitorings } = this.props;

        const active = this.state.active.clone().add(count, expanded ? 'M' : 'w');

        this.setState({
            active: active
        });

        getMonitorings(active.format('YYYY'), active.format('MM'));
    };

    handleClick = date => {
        const { range, onChange } = this.props;

        let value = {
            from: null,
            to: null
        };
        if (!range) {
            // without range selection
            value = date.clone();
        } else if ((this.state.from && this.state.to) || (!this.state.from && !this.state.to)) {
            // start selecting
            value = { from: date.clone(), to: null };
        } else if (this.state.from.isAfter(date)) {
            // end is before start
            value = { from: date.clone(), to: this.state.from.clone() };
        } else {
            // finish selecting
            value = { from: this.state.from, to: date.clone() };
        }

        this.setState(value);
        onChange(value);
    };

    handleHover = date => {
        this.setState({ hover: date })
    };

    handleFastChange = (from, to) => {
        const { onChange } = this.props;

        const value = {
            from: from,
            to: to
        };

        this.setState(value);
        onChange(value);

        this.forceUpdate();
    };

    handleExpand = () => {
        this.setState({ expanded: !this.state.expanded})
    };

    render() {
        const { active } = this.state;

        return <Picker direction="column">
            <Navigation active={active} onClickLeft={() => this.handleMoveMonth(-1)} onClickRight={() => this.handleMoveMonth(1)}/>
            <DateNames/>
            <Dates active={active}
                   from={this.state.from}
                   to={this.state.to}
                   hover={this.state.hover}
                   onClick={this.handleClick}
                   onHover={this.handleHover}
                   expanded={this.state.expanded}
            />
            {this.props.expanded && this.props.range && <FastNavigation onChange={this.handleFastChange}/>}
            {!this.props.expanded && <div className="text-center" onClick={this.handleExpand}>
                {this.state.expanded
                    ? <ExpandArrow src={require('../../assets/img/wide-arrow-up.png')} alt=""/>
                    : <ExpandArrow src={require('../../assets/img/wide-arrow-down.png')} alt="" />}
            </div>}
        </Picker>
    }
}

export default connect(
    (state, props) => {
        return {
            client: state.client,
            monitorings: state.monitorings
        }
    },
    (dispatch, props) => {
        return {
            getMonitorings: (client, year, month) => dispatch(monitorings(client, year, month))
        }
    },
    (stateProps, dispatchProps, ownProps) => {
        return Object.assign({}, ownProps, stateProps, dispatchProps, {
            getMonitorings: (year, month) => dispatchProps.getMonitorings(stateProps.client.data.id, year, month)
        });
    },
)(DatePicker);