//@flow

import * as React from 'react';
import {connect} from 'react-redux';

import {Tooltip} from "../../components";

class Avatar extends React.PureComponent {

    render(): React.Node {
        const {profile} = this.props;

        return <div id="avatar">
            <img src={require('../../../assets/img/male.png')} alt="avatar" className="rounded-circle"
                 style={{width: '40px', height: '40px'}}/>
            <Tooltip placement="bottom" target="avatar" color="cloudy-blue">{profile.username}</Tooltip>
        </div>
    }
}

export default connect(
    (state, props) => {
        return {
            profile: state.profile.data
        }
    }
)(Avatar);