//@flow

import * as React from 'react';

import { Flex } from '.';

type ContentProps = {
    children: React.Node
}

export default class extends React.PureComponent<ContentProps> {

    render() {
        const { children } = this.props;

        return <Flex flex>
            {children}
        </Flex>
    }
}
