//@flow

import * as React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import CategoriesListItem from './CategoriesListItem';
import SidebarTitle from '../../../components/SidebarTitle';
import categories from '../../../../actions/categories';

type CategoriesListProps = {
    onClick: () => void,
    categories: {
        items: [{
            id: number,
            name: string,
            done_count: number,
        }]
    }
}

class CategoriesList extends React.PureComponent<CategoriesListProps> {

    componentWillMount() {
        const { getCategories } = this.props;

        getCategories();
    }

    render(): React.Node {
        const { categories: { items }, dailyCounts, onClick, selected } = this.props;

        let daily = [0];
        dailyCounts.forEach(item => {
            daily[0] += Number(item.count);
            daily[item.id] = item.count
        });
        let total = 0;
        items.forEach(item => total += item.done_count);

        return <div className="p-3">
            <div>
                <SidebarTitle>Kategórie</SidebarTitle>
                <hr />
            </div>
            <CategoriesListItem id={null} name="Všetky kategórie" daily={daily[0]} done={total} onClick={onClick} selected={null === selected}/>
            {items.map(item => <CategoriesListItem key={item.id} id={item.id} name={item.name} daily={daily[item.id]} done={item.done_count} onClick={onClick} selected={item.id === selected}/>)}
        </div>
    }
}

export default connect(
    (state, props) => {
        return {
            client: state.client,
            categories: state.categories,
            dailyCounts: state.histogram['dailyCounts'] ? state.histogram['dailyCounts'].items : [],
            selected: formValueSelector('monitoring')(state, 'category')
        }
    },
    (dispatch, props) => {
        return {
            getCategories: client => dispatch(categories(client.data.id))
        }
    },
    (stateProps, dispatchProps, ownProps) => {
        return Object.assign({}, ownProps, stateProps, dispatchProps, {
            getCategories: () => dispatchProps.getCategories(stateProps.client)
        });
    }
)(CategoriesList);
