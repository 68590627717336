//@flow

import * as React from 'react';
import styled from 'styled-components';

import ArticleList from './ArticleList';
import { Flex, Scrollable } from '../../../components'
import Article from "../../../components/Article";
import {gallery09, gallery25} from "../../../variables";

const StyledList = styled(Flex)`
    background-color: ${gallery25}
`;

const StyledContent = styled(Scrollable)`
    background-color: ${gallery09}
`;

type ArticlesProps = {

}

type ArticlesState = {
    id: ?number
}

export default class extends React.Component<ArticlesProps, ArticlesState> {

    constructor(props: ArticlesProps) {
        super(props);

        this.state = {
            id: null
        }
    }

    handleSelect = (id: number) => {
        this.setState({
            id: id
        })
    };

    render() {
        return <Flex flex>
            <StyledList id="article-list" flex={2} direction="column">
                <ArticleList onSelect={this.handleSelect} selected={this.state.id}/>
            </StyledList>
            <StyledContent flex={3} direction="column">
                <Article id={this.state.id}/>
            </StyledContent>
        </Flex>
    }
}
