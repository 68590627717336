import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import { reducer as uiReducer } from 'redux-ui';

import loginReducer from './login';
import monitoringReducer from './monitoring';
import categoriesReducer from './categories';
import exportsReducer from './exports';
import sourcesReducer from './sources';
import clientsReducer from './clients';
import filterReducer from './filter';
import articleReducer from './article';
import changeClientReducer from './change-client';
import exportTypesReducer from './export-types';
import monitoringsReducer from './monitorings';
import profileReducer from './profile';
import graphqlReducer from './graphql';

import { histogram } from '../v2/pages/Analysis/reducers';

import {LOGOUT_REQUEST} from "../actions/logout";

const appReducer = combineReducers({
    form: formReducer,
    ui: uiReducer,

    login: loginReducer,
    monitoring: monitoringReducer,
    categories: categoriesReducer,
    exports: exportsReducer,
    sources: sourcesReducer,
    clients: clientsReducer,
    article: articleReducer,
    filter: filterReducer,
    client: changeClientReducer,
    exportTypes: exportTypesReducer,
    monitorings: monitoringsReducer,
    profile: profileReducer,
    graphql: graphqlReducer,

    histogram
});

const rootReducer = (state, action) => {
    if (LOGOUT_REQUEST === action.type) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
