import * as React from 'react';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { Modal, ModalBody, Input } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

import ArrayField from '../../../../fields/ArrayField';
import { sources } from '../../../../actions';
import { Checkbox, Pill, Icon, Flex } from "../../../components";
import {seagull} from "../../../variables";

class Source extends React.PureComponent {

    render() {
        const { source, input } = this.props;

        return <div className="col-md-3">
            <ArrayField key={source.id} input={input} label={source.name} option={source.id} component={Checkbox}>{source.name}</ArrayField>
        </div>
    }
}

class FilteredSource extends React.PureComponent {

    render() {
        const { filter, ...props } = this.props;

        if (filter && (-1 === this.props.source.name.toLowerCase().indexOf(filter.toLowerCase()))) {
            return null;
        }

        return <Source {...props} />
    }
}

class SourceGroup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            opened: false
        }
    }

    handleSelectAll = () => {
        const { selectAll } = this.props;

        selectAll();
    };

    handleUnselectAll = () => {
        const { unselectAll } = this.props;

        unselectAll();
    };

    handleInvertSelection = () => {
        const { invertSelection } = this.props;

        invertSelection();
    };

    handleToggle = () => {
        this.setState({opened: !this.state.opened});
    };

    render() {
        const { sourceGroup, input, label, groupSelected, filter } = this.props;

        return <div className="mb-3">
            <div className="row">
                <div className="col-md-12">
                    <Flex className="bg-white align-items-center justify-content-between source-group-heading p-1">
                        <div>
                            {this.state.opened ? <img className="cursor-pointer" src={require('../../../../assets/icons/dropdown.svg')} alt="" onClick={this.handleToggle}/>
                                : <img className="cursor-pointer" src={require('../../../../assets/icons/dropdown.svg')} alt="" style={{transform: 'rotate(-90deg)'}} onClick={this.handleToggle}/>}
                            <h3 className="m-0 ml-2 cursor-pointer d-inline-block" onClick={this.handleToggle}>{label}</h3>
                            <span className="ml-3 mr-6 text-gray">{groupSelected.length}/{sourceGroup.length}</span>
                        </div>
                        <div>
                            <Icon type="select" size={30} alt="select all" onClick={this.handleSelectAll}/>
                            <Icon type="deselect" size={30} alt="unselect all" onClick={this.handleUnselectAll}/>
                            <Icon type="invert" size={30} alt="invert selection" onClick={this.handleInvertSelection}/>
                        </div>
                    </Flex>
                </div>
            </div>
            {(this.state.opened || filter) && <div className="row p-3">
                {sourceGroup.map(source => <FilteredSource filter={filter} key={source.id} input={input} source={source}/>)}
            </div>}
        </div>
    }
}

SourceGroup = connect(
    (state, props) => {
        const ids = props.sourceGroup.map(source => source.id);
        const selected = formValueSelector('filter')(state, 'sources');
        return {
            selected: selected,
            groupSelected: ids.filter(id => -1 !== selected.indexOf(id))
        }
    },
    (dispatch, props) => {
        return {
            selectAll: sources => dispatch(change('filter', 'sources', sources)),
            unselectAll: sources => dispatch(change('filter', 'sources', sources)),
            invertSelection: sources => dispatch(change('filter', 'sources', sources))
        }
    },
    (stateProps, dispatchProps, ownProps) => {
        const ids = ownProps.sourceGroup
            .filter(source => !ownProps.filter || (ownProps.filter && (-1 !== source.name.toLowerCase().indexOf(ownProps.filter.toLowerCase()))))
            .map(source => source.id)
        ;
        return Object.assign({}, ownProps, stateProps, dispatchProps, {
            selectAll: () => dispatchProps.selectAll([
                ...stateProps.selected,
                ...ids
            ]),
            unselectAll: () => dispatchProps.unselectAll(stateProps.selected.filter(id => -1 === ids.indexOf(id))),
            invertSelection: () => dispatchProps.invertSelection([
                ...stateProps.selected.filter(id => -1 === ids.indexOf(id)),
                ...ids.filter(id => -1 === stateProps.selected.indexOf(id))
            ])
        });
    }
)(SourceGroup);

class Sources extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            filter: null
        }
    }

    componentWillMount() {
        const { getSources } = this.props;

        getSources();
    }

    handleToggle = () => {
        this.setState({ opened: !this.state.opened });
    };

    handleClose = () => {
        const { onClose } = this.props;

        onClose();
    };

    handleSelectAll = () => {
        const { selectAll } = this.props;

        selectAll(this.state.filter);
    };

    handleUnselectAll = () => {
        const { unselectAll } = this.props;

        unselectAll(this.state.filter);
    };

    handleInvertSelection = () => {
        const { invertSelection } = this.props;

        invertSelection(this.state.filter);
    };

    handleFilter = e => {
        this.setState({ filter: e.target.value });
    };

    get label() {
        const { input, sources } = this.props;

        return <span>Zdroje
            {input.value.length ? <span className="text-cloudy-blue font-weight-bold mx-2">
                <FontAwesome name="circle" className="fas"/> {input.value.length}/{sources.items.length}
            </span> : null}
        </span>
    }

    get active() {
        const { input } = this.props;

        return input.value.length ? seagull : "white";
    }

    get footer(): React.Node {
        return <Flex flex className="justify-content-between">
            <Pill color="white" backgroundColor="black" onClick={this.handleUnselectAll}>Vymazať</Pill>
            <Pill color="white" backgroundColor="black" onClick={this.handleToggle}>Potvrdiť</Pill>
        </Flex>
    }

    render() {
        const { sourceGroups, sources, selected, input } = this.props;

        return <div className="d-inline-flex m-1">
            <Pill onClick={this.handleToggle} backgroundColor={this.active} color="black">
                <span className="mx-4 my-2">{this.label}</span>
            </Pill>
            <Modal name="filter-sources" isOpen={this.state.opened} toggle={this.handleToggle} onClosed={this.handleClose} size="lg">
                <ModalBody>
                    <div className="d-flex align-items-center mb-4">
                        <FontAwesome name="arrow-left" size="2x" className="fal" onClick={this.handleToggle}>Back</FontAwesome>
                        <h1 className="m-0 ml-3 flex">Zdroje</h1>
                        <Input className="mx-3" onChange={this.handleFilter}/>
                        <span className="text-gray text-bigger mr-3">{(selected && selected.length)  || 0}/{sources.items.length}</span>
                        <Icon type="select" size={40} alt="select all" onClick={this.handleSelectAll}/>
                        <Icon type="deselect" size={40} alt="unselect all" onClick={this.handleUnselectAll}/>
                        <Icon type="invert" size={40} alt="invert selection" onClick={this.handleInvertSelection}/>
                    </div>
                    {Object.keys(sourceGroups).map((sourceGroup, index) => <SourceGroup key={index} input={input} label={sourceGroup} index={index} sourceGroup={sourceGroups[sourceGroup]} filter={this.state.filter}/>)}
                    {this.footer}
                </ModalBody>
            </Modal>
        </div>
    }
}

export default connect(
    (state, props) => {
        const sourceGroups = [];
        state.sources.items.map(source => {
            if (undefined === sourceGroups[source.source_group.name]) {
                sourceGroups[source.source_group.name] = [];
            }
            sourceGroups[source.source_group.name].push(source);
            return null;
        });

        return {
            sourceGroups: sourceGroups,
            sources: state.sources,
            selected: formValueSelector('filter')(state, 'sources')
        }
    },
    (dispatch, props) => {
        return {
            getSources: () => dispatch(sources()),
            selectAll: sources => dispatch(change('filter', 'sources', sources)),
            unselectAll: sources => dispatch(change('filter', 'sources', sources)),
            invertSelection: sources => dispatch(change('filter', 'sources', sources))
        }
    },
    (stateProps, dispatchProps, ownProps) => {
        return Object.assign({}, ownProps, stateProps, dispatchProps, {
            selectAll: (filter) => {
                const ids = stateProps.sources.items
                    .filter(source => !filter || (filter && (-1 !== source.name.toLowerCase().indexOf(filter.toLowerCase()))))
                    .map(source => source.id);
                return dispatchProps.selectAll(ids)
            },
            unselectAll: (filter) => {
                const ids = stateProps.sources.items
                    .filter(source => !filter || (filter && (-1 !== source.name.toLowerCase().indexOf(filter.toLowerCase()))))
                    .map(source => source.id);
                return dispatchProps.unselectAll(stateProps.selected.filter(id => -1 === ids.indexOf(id)))
            },
            invertSelection: (filter) => {
                const ids = stateProps.sources.items
                    .filter(source => !filter || (filter && (-1 !== source.name.toLowerCase().indexOf(filter.toLowerCase()))))
                    .map(source => source.id);
                return dispatchProps.invertSelection([
                    ...stateProps.selected.filter(id => -1 === ids.indexOf(id)),
                    ...ids.filter(id => -1 === stateProps.selected.indexOf(id))
                ])
            }
        });
    }
)(Sources);