import * as React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';

import { exportTypes } from '../../../actions';
import { SidebarItem, SidebarTitle, Flex } from '../../components';
import DoubleColumnLayout from '../DoubleColumnLayout';
import Exports from './Exports';
import {dustyGray25} from '../../variables';

class Sidebar extends React.PureComponent {

    componentDidMount() {
        const { getExportTypes } = this.props;

        getExportTypes();
    }

    render() {
        const { exportTypes, match } = this.props;

        return <Flex flex direction="column" className="py-3">
            <SidebarTitle>Typy exportov</SidebarTitle>
            <hr />
            <div>
                <Link to="/archive/standard"><SidebarItem selected={'/archive/standard' === match.url}>Štandardné exporty</SidebarItem></Link>
                {exportTypes.items.map((item, key)=> <div key={key} className="cursor-pointer">
                    <Link className="px-2 py-1" to={`/archive/${item.type}`} style={{display: 'block', background: `/archive/${item.type}` === match.url ? dustyGray25 : 'transparent'}}>{item.name}</Link>
                </div>)}
            </div>
            {/*<Link to="/archive/special"><SidebarItem selected={'/archive/special' === match.url}>Špeciálne exporty</SidebarItem></Link>*/}
            <Link to="/archive/custom"><SidebarItem selected={'/archive/custom' === match.url}>Vlastné exporty</SidebarItem></Link>
        </Flex>
    }
}

Sidebar = connect(
    (state, props) => {
        return {
            client: state.client,
            exportTypes: state.exportTypes
        }
    },
    (dispatch, props) => {
        return {
            getExportTypes: client => dispatch(exportTypes(client))
        }
    },
    (stateProps, dispatchProps, ownProps) => {
        return Object.assign({}, ownProps, stateProps, {
            getExportTypes: () => dispatchProps.getExportTypes(stateProps.client.data.id)
        });
    }
)(Sidebar);

Sidebar = withRouter(Sidebar);

export default class extends React.PureComponent {

    render(): React.Node {
        const {match: {params: {type}}} = this.props;

        return <DoubleColumnLayout sidebar={<Sidebar/>}>
            <Exports type={type}/>
        </DoubleColumnLayout>
    }
}