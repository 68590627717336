import {authorized} from "./axios";

export const CLIENTS_REQUEST = 'core/CLIENTS_REQUEST';
export const CLIENTS_RECEIVE = 'core/CLIENTS_RECEIVE';
export const CLIENTS_ERROR = 'core/CLIENTS_ERROR';

export default () => {
    return (dispatch, getState) => {
        dispatch({
            type: CLIENTS_REQUEST
        });
        return authorized(getState()).get(`/clients`)
            .then(response => {
                return dispatch({
                    type: CLIENTS_RECEIVE,
                    payload: response.data
                });
            })
            .catch(error => {
                return dispatch({
                    type: CLIENTS_ERROR,
                    payload: error
                });
                throw error;
            })
        ;
    }
}
