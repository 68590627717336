import * as React from 'react';
import 'select2';
import Select from 'react-select';

export default class extends React.PureComponent {

    static defaultProps = {
        placeholder: false,
        onSelect: () => {}
    };

    handleSelect = async value => {
        const { input: { onChange }, onSelect } = this.props;

        await onChange(value.value);
        onSelect();
    };

    get styles() {
        const {styles} = this.props;

        return {
            control: (provided, state) => ({
                ...provided,
                background: 'transparent'
            }),
            indicatorsContainer: () => ({
                display: 'none'
            }),
            singleValue: (provided, state) => ({
                'font-weight': 500
            }),
            ...styles
        }
    }

    get value() {
        const {options, input: {value}} = this.props;

        return options.find(e => e.value === value);
    }

    render() {
        const { options, input, meta, ...rest } = this.props;

        return <Select onChange={this.handleSelect} options={options} {...rest} value={this.value} styles={this.styles}/>;
    }
}
