//@flow

import * as React from "react";
import classnames from 'classnames';

import {Link} from "react-router-dom";
import {Icon} from "../../components";
import type {MenuItemType} from "../../components/Icon";

type MenuItemProps = {
    to: string,
    target: string,
    icon: MenuItemType,
    children: React.Node,
    match: {
        path: string
    }
}

class MenuItem extends React.PureComponent<MenuItemProps> {

    render() {
        const {to, target, icon, children, active} = this.props;

        return <div className={classnames("py-3 text-center", {"bg-light-purple": active})}>
            <Link id={target} to={to} className="text-white">
                <Icon type={icon} size={30} />
                <div>{children}</div>
            </Link>
        </div>
    }
}

export default MenuItem;