import * as React from "react";
import 'react-vis/dist/style.css';
import moment from 'moment';

import connect from './connect';
import Graph from './BarSeriesGraph';
import {sourceDateSummary} from "./queries";

class SourceDateSummaryGraph extends React.PureComponent {

    get results() {
        const { histogram: { data }} = this.props;

        let result = {};
        data.forEach(item => {
            result = {
                ...result,
                [item.keys[0]]: item.count
            };
        });

        return result;
    };

    get legend() {
        return Object.keys(this.results);
    }

    get series() {
        return [{
            name: 'Zdroje',
            data: Object.values(this.results)
        }];
    }

    render() {
        return <Graph legend={this.legend} series={this.series} title="Počet článkov podľa zdroja - TOP 20"/>
    }
}

export default connect({
    name: 'sourceDate',
    query: (name, client, from, to) => sourceDateSummary(name, {
        client: client,
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD')
    }),
    initialValues: {
        date: {
            from: moment().subtract(1, 'M').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD')
        }
    }
})(SourceDateSummaryGraph);
