import React, { Component, Node } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import classnames from 'classnames';

export default class extends Component {

    static propTypes = {
        placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
        color: PropTypes.oneOf(['cloudy-blue', 'black', 'white']),
        target: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
        children: PropTypes.any.isRequired,
    };

    static defaultProps = {
        target: () => document.getElementById('root')
    };

    constructor(props) {
        super(props);

        this.state = {
            open: false
        };
    }

    handleToggle = () => {
        this.setState({
            open: !this.state.open
        })
    };

    render(): Node {
        const { placement, color, target, children, ...rest } = this.props;

        const className = classnames({'tooltip-cloudy-blue': 'cloudy-blue' === color});

        return <Tooltip className={className} placement={placement} target={target} isOpen={this.state.open} toggle={this.handleToggle} {...rest}>{children}</Tooltip>
    }
}