import * as React from "react";
import Pill from "./Pill";
import {PopoverBody, Popover} from 'reactstrap';
import shortid from 'shortid';

export default class extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            id: shortid()
        }
    }

    handleToggle = () => {
        this.setState({opened: !this.state.opened});
    };

    render() {
        const {label, children} = this.props;

        return <div className="d-inline-flex">
            <Pill color="white" backgroundColor="black" id={`dropdown${this.state.id}`} onClick={this.handleToggle} className="m-2 px-3 py-1">{label}</Pill>
            <Popover target={`dropdown${this.state.id}`} placement="bottom" isOpen={this.state.opened} toggle={this.handleToggle}>
                <PopoverBody>
                    {children}
                </PopoverBody>
            </Popover>
        </div>
    }

}