import * as React from "react";
import 'react-vis/dist/style.css';
import moment from 'moment';

import connect from './connect';
import Graph from './BarSeriesGraph';
import {categoryRatingSummary} from "./queries";

class RatingSummaryGraph extends React.Component {

    get results() {
        const { histogram: { data }} = this.props;

        let result = [[], [], [], []];
        data.forEach(item => {
            result = {
                ...result,
                [item.keys[1]]: {
                    ...result?.[item.keys[1]],
                    [item.keys[0]] : item.count
                }
            };
        });

        return result;
    }

    get legend() {
        const results = this.results;

        return Object.keys(results[0]);
    }

    get series() {
        const results = this.results;

        return [
            {name: "Nehodnotene", data: Object.values(results[0])},
            {name: 'Negatívne', data: Object.values(results[1])},
            {name: 'Neutrálne', data: Object.values(results[2])},
            {name: "Pozitivne", data: Object.values(results[3])},
        ]
    }

    render() {
        return <Graph legend={this.legend} series={this.series} title="Polarita článkov podľa kategórií"/>
    }
}

export default connect({
    name: 'ratingCategorySummary',
    query: (name, client, from, to) => categoryRatingSummary(name, {
        client: client,
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD')
    }),
    initialValues: {
        date: {
            from: moment().subtract(1, 'M').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD')
        }
    }
})(RatingSummaryGraph);
