//@flow

import * as React from "react";
import styled from 'styled-components';
import { connect } from 'react-redux';
import {withRouter} from "react-router";

import { Flex, Logo } from '../../components';
import Avatar from './Avatar';
import Logout from './Logout';
import MenuItem from './MenuItem';

import { dustyGray25, shark, wildSand } from '../../variables';
import {profile} from "../../../actions";

const Menu = styled.div`
    width: 91px;
    background-color: ${shark}
`;

const Header = styled(Flex)`
    height: 86px;
    background-color: ${wildSand};
    border-bottom: 1px solid ${dustyGray25};
`;

const Toolbar = styled(Flex)`
    height: 50px;
    background-color: ${wildSand};
    border-bottom: 1px solid ${dustyGray25};
`;

type MainLayoutProps = {
    header: React.Node,
    toolbar: React.Node,
    children: React.Node
}

type ClientLogoProps = {
    client: {
        logo: {
            file: string
        },
        name: string
    }
};

class ClientLogo extends React.PureComponent<ClientLogoProps> {

    render() {
        const { client } = this.props;

        if (!client) {
            return null;
        }

        return <div className="px-5 py-3">
            {client.logo ? <Logo src={client.logo.file} alt={client.name} /> : <Logo src="https://via.placeholder.com/60" alt="placeholder"/>}
        </div>
    }
}

ClientLogo = connect(
    (state, props) => {
        return {
            client: state.client.data
        }
    }
)(ClientLogo);

class AgencyLogo extends React.PureComponent {

    render() {
        const {file} = this.props;

        if (!file) {
            return null;
        }
        return file && <Logo src={file} />;
    }
}

AgencyLogo = connect(
    (state, props) => {
        return {
            file: state.client.data && state.client.data.supplier && state.client.data.supplier.logo_name
        }
    }
)(AgencyLogo);

class MainLayout extends React.PureComponent<MainLayoutProps> {

    componentDidMount() {
        const { getProfile } = this.props;

        getProfile();
    }

    render(): React.Node {
        const { children, header, toolbar, match, location, profile } = this.props;

        return <Flex className="flex-container" direction="column" flex>
            <Flex flex={true}>
                <Menu id="menu">
                    <div className="p-3 text-center">
                        <Avatar />
                    </div>
                    <div className="p-3 text-center">
                        <Logout/>
                    </div>
                    <MenuItem to="/monitoring" target="button-monitoring" icon="monitoring/white" active={match.path === '/monitoring'}>Monitoring</MenuItem>
                    <MenuItem to="/export" target="button-export" icon="export/white" active={match.path === '/export'}>Export</MenuItem>
                    <MenuItem to="/analysis/category_rating" target="button-analysis" icon="analyze/white" active={match.path.match('/analysis/.*')}>Analýza</MenuItem>
                    <MenuItem to={{pathname: location.pathname, state: {modal: 'help'}}} target="button-help" icon="help-menu" active={'history' === location.state?.modal}>Nápoveda</MenuItem>
                    {1 < profile.data.clients?.length && <MenuItem to="/clients" target="button-clients" icon="client/white" active={match.path === '/clients'}>Klienti</MenuItem>}
                </Menu>
                <Flex direction="column" flex>
                    <Header id="header" className="align-items-center justify-content-between">
                        <Flex flex={true} className="align-items-center">
                            <ClientLogo/>
                            {header}
                        </Flex>
                        <AgencyLogo/>
                    </Header>
                    <Toolbar id="toolbar">{toolbar}</Toolbar>
                    <Flex flex>
                        {children}
                    </Flex>
                </Flex>
            </Flex>
            <Flex id="footer" className="bg-black text-white justify-content-between">
                <div className="p-1">MediaBase.sk</div>
                <div className="p-1">All rights reserved. Copyright © 2018</div>
            </Flex>
        </Flex>
    }
}

MainLayout = connect(
    (state, props) => {
        return {
            profile: state.profile
        }
    },
    (dispatch, props) => {
        return {
            getProfile: () => dispatch(profile())
                .then(({payload}) => {
                    window.mixpanel.identify(payload.id);
                    window.mixpanel.people.set({
                        "$email": payload.email,    // only special properties need the $
                        "$created": payload.created_at,
                        "$last_login": new Date(),         // properties can be dates...
                        "gender": payload.gender                   // feel free to define your own properties
                    });
                })
        }
    }
)(MainLayout);

export default withRouter(MainLayout);
