import { API_URL } from '../config';
import axios from 'axios';
import qs from 'qs';

export const ARTICLES_REQUEST = 'core/ARTICLES_REQUEST';
export const ARTICLES_RECEIVE = 'core/ARTICLES_RECEIVE';
export const ARTICLES_ERROR = 'core/ARTICLES_ERROR';

export default (client, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: ARTICLES_REQUEST
        });
        return axios.get(API_URL + `/client/${client}/articles/filter`, {
            params: data,
            paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets'}),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getState().login.data
            }
        })
            .then(response => {
                dispatch({
                    type: ARTICLES_RECEIVE,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: ARTICLES_ERROR,
                    payload: error
                });
                throw error;
            })
        ;
    }
}
