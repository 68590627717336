import styled from 'styled-components';

export const Content = styled.div`
    background-color: #f9f9f9
`;

export const Sidebar = styled.div`
    background-color: #f4f4f4;
    width: 200px
`;

export const ScrollableSidebar = styled(Sidebar)`
    overflow: auto
`;

export const Scrollbar = styled.div`
    overflow: auto
`;