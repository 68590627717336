//@flow

import * as React from 'react';

import Input from './Input';

export default class extends React.PureComponent {

    render() {
        const { input, meta, ...rest } = this.props;

        return <Input {...input} {...rest} />
    }
}